/*------------------------------------
  Navigation Dropdown Static
------------------------------------*/

.u-dropdown-static {
  position: static !important;
  display: block;
  max-height: 0;
  @include px-to-rem(margin, 0 15px);
  @include px-to-rem(padding, 5px 0);
  transform: translate3d(0, 0, 0) !important;
  border: none;
  background-color: transparent;
  overflow: hidden;
  opacity: 0;
  transition: opacity .3s, max-height .3s;

  &.show {
    max-height: 100%;
    opacity: 1;
  }
}