/*------------------------------------
  Navigation Style v5
------------------------------------*/

.navbar .u-main-nav-v5 {
	.nav-link {
		@extend %u-main-nav-base-link;

		color: $g-color-gray-dark-v2;

		@include px-to-rem(padding, 4px 0);
		border-bottom: 2px solid transparent;
	}

	.nav-item {

		&.hs-has-sub-menu,
		&.hs-has-mega-menu,
		&.dropdown {
			@extend %u-main-nav-has-sub-menu;

			> a {
				@include px-to-rem(padding-right, 20px);

				&::after {
					right: 0;
				}
			}
		}

		&.active > .nav-link,
		&:hover > .nav-link,
		&:focus > .nav-link,
		&.show > .nav-link,
		&.hs-sub-menu-opened > .nav-link,
		.nav-link:focus {
			border-color: $g-color-primary;
		}
	}
}