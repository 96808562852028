//
// Navigation Dropdown Style Inline
//

@mixin nav-dropdown-inline($media-type) {
  @if $media-type == xs {
    $media-type: '';
  } @else if $media-type == sm {
    $media-type: --sm;
  } @else if $media-type == md {
    $media-type: --md;
  } @else if $media-type == lg {
    $media-type: --lg;
  }  @else {
    $media-type: --xl;
  }

  .u-navbar {
    &--inline-submenu#{$media-type} {
      .dropdown-menu {
        white-space: nowrap;
        top: 98%;
        left: 50%;
        border-radius: 0;
        overflow: visible !important;
        border-left: none;
        border-right: none;
        transform: translate3d(-50%, 0, 0);

        &::after,
        &::before {
          content: "";
          position: absolute;
          top: -1px;
          bottom: 0;
          width: 2000%;
          border-top: inherit;
          border-bottom: inherit;
          background-color: inherit;
        }

        &::after {
          left: 100%;
        }
        &::before {
          right: 100%;
        }
        & > li {
          display: inline-block;
        }
      }
    }
    &--inline-submenu#{$media-type}.u-navbar--overflow {
      .dropdown,
      .container {
        position: static;
      }

      .dropdown-menu {
        white-space: normal;
        left: 0;
        transform: translate3d(0, 0, 0);
        margin-top: 0;
      }
    }
  }
}