/*------------------------------------
  Nonstandard-bg
------------------------------------*/
[class*="u-ns-bg-v"] {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    display: block;
    z-index: 3;
  }
}