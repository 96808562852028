/*------------------------------------
  Steps v2
------------------------------------*/

.u-steps-v2 {
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: $g-color-gray-light-v4;
  border-radius: .25rem;

  &::after {
    content: "";
    display: table;
    width: 100%;
  }

  > * {
    display: inline-block;
    vertical-align: middle;
  }

  &__item {
    float: left;
    margin-right: 15px;

    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media (min-width: $g-md) {
  .u-steps-v2 {
    padding: 0;
    margin-bottom: 0;
    background-color: transparent;
    border-radius: 0;

    &__indicator {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $g-color-primary;
      box-shadow: 0;
      transform: translateX(-50%);
      transition: {
        property: box-shadow;
        duration: .2s;
        timing-function: ease-in;
      }
    }

    &__item {
      text-align: center;
      list-style: none;
      margin-right: 40px;

      a,
      span {
        position: relative;
        display: block;
        color: $g-color-gray-dark-v2;
        padding-bottom: 20px;
        transition: {
          property: color;
          duration: .2s;
          timing-function: ease-in;
        }

        &::before {
          content: "";
          position: absolute;
          bottom: 4px;
          left: 50%;
          display: block;
          width: 120px;
          height: 4px;
          margin-left: 6px;
        }
      }

      a {
        &::before {
          background-color: $g-color-primary;
        }

        .u-steps-v2__indicator {
          background-color: $g-color-primary;
        }

        &:hover {
          text-decoration: none;
        }
      }

      span {
        &::before {
          background-color: $g-color-gray-light-v4;
        }

        .u-steps-v2__indicator {
          background-color: $g-color-gray-light-v4;
        }
      }

      &:last-child {
        a,
        span {
          &::before {
            display: none;
          }
        }
      }

      &:hover {
        a {
          color: $g-color-primary;

          .u-steps-v2__indicator {
            box-shadow: 0 0 0 3px rgba($g-color-primary, .3);
          }
        }
      }

      &.active {
        a,
        span {
          .u-steps-v2__indicator {
            background-color: $g-color-primary;
          }
        }
      }
    }
  }
}
