/*------------------------------------
  JS
------------------------------------*/

.js-carousel_single-item {
  max-width: 100%;
  position: relative;
}

.js-next,
.js-prev,
.js-thumb {
  cursor: pointer;
}

.js-next,
.js-prev {
  z-index: 10;
}

.js-thumb {
  img {
    display: inline-block;
  }
}

.js-carousel_single-item-thumbs5__thumbs {
  .js-thumb {
    opacity: .3;
    transition: {
      property: opacity;
      duration: .2s;
      timing-function: ease-in;
    }
  }

  .slick-center {
    opacity: 1;
  }
}

.js-carousel_single-item-thumbs5--v2__thumbs {
  .js-thumb {
    opacity: .4;
    transition: {
      property: opacity;
      duration: .2s;
      timing-function: ease-in;
    }
  }

  .slick-center {
    opacity: 1;
  }
}

.js-carousel_single-item-thumbs5--v3__thumbs {
  .js-thumb {
    opacity: .4;
    transition: {
      property: opacity;
      duration: .2s;
      timing-function: ease-in;
    }
  }

  .slick-center {
    opacity: 1;
  }
}

.u-pagi-control--v2 {
  display: block;
  width: 12px;
  height: 12px;
  margin: 0 3px;
  border-radius: 50%;
  background: {
    color: $g-color-gray-light-v3;
  }
  opacity: .3;
  cursor: pointer;
}

.u-pagi-control-red {
  .slick-active & {
    background: {
      color: $g-color-red;
    }
  }
}

.u-pagi-control-grey {
  .slick-active & {
    opacity: 1;
  }
}

.u-carousel--v3 {
  .js-prev,
  .js-next {
    top: 50%;
    opacity: 0;
    transition: {
      property: opacity;
      duration: .2s;
      timing-function: ease-in;
    }
  }

  .g-height-40 {
    &.js-prev,
    &.js-next {
      margin-top: -20px;
      left: 50%;
    }

    &.js-prev {
      margin-left: -99px;
    }

    &.js-next {
      margin-left: 65px;
    }
  }

  &:hover {
    .js-prev,
    .js-next {
      opacity: 1;
    }
  }
}

.u-carousel--v4 {
  margin-left: -15px;
  margin-right: -15px;
  .js-prev,
  .js-next {
    top: 50%;
    opacity: 0;
    transition: {
      property: opacity;
      duration: .4s;
      timing-function: ease-in;
    }
  }

  .g-height-40 {
    &.js-prev,
    &.js-next {
      margin-top: -20px;
      left: 50%;
    }

    &.js-prev {
      margin-left: -160px;
    }

    &.js-next {
      margin-left: 125px;
    }
  }

  &:hover {
    .js-prev,
    .js-next {
      opacity: 1;
    }
  }
}

/*--------------------------------------------------
  Filter v1
----------------------------------------------------*/
.u-filter-v1 > li:not(:last-child) {
  &::after {
    content: "|";
    @include px-to-rem(font-size, 10px);
    margin: 0 7px 0 14px;
    position: relative;
    top: -2px;
    line-height: inherit;
    display: inline-block;
    vertical-align: middle;
    color: $g-color-gray-light-v3;
  }
}

.u-filter-v1 > li:hover,
.u-filter-v1 [class*="active"] {
  color: $g-color-primary;
}

/*--------------------------------------------------
  Carousel v10
----------------------------------------------------*/
.u-carousel-v10 {
  .slick-dots {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: auto;
    visibility: hidden;
    margin: 0;
    padding: 0;

    li {
      display: block;
      margin-bottom: 5px;
    }

    button {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      color: transparent;
      background: $g-color-gray-light-v2;
      border: none;
      outline: none;
    }

    .slick-active button {
      background: $g-color-primary;
    }
  }
}

.u-carousel-v10-nav {
  margin-top: -135px;

  .js-slide {
    position: relative;
    cursor: pointer;
    height: 100%;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($g-color-black, .2);
      transition: opacity 0.3s ease-in-out;
    }
    &.slick-current {
      &::after {
        opacity: 0;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .u-carousel-v10 .slick-dots {
    visibility: visible;
  }

  .u-carousel-v10-nav {
    display: none;
  }
}

