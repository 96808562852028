//
// Divider Linear Gradient Style
//

/* Linear Gradient Divider */
.u-divider {
  &-linear-gradient {
    height: 1px;
    border: none;
  }
  &-linear-gradient--gray-light-v2 {
    background-image: linear-gradient(to right, transparent, $g-color-gray-light-v2, transparent);
  }
  &-linear-gradient--gray-light-v3 {
    background-image: linear-gradient(to right, transparent, $g-color-gray-light-v3, transparent);
  }
}