/*------------------------------------
  Tooltips
------------------------------------*/

.u-tooltip {
  &--v1 {
    color: $g-color-white;
    background-color: $g-color-black;
    padding: 2px 8px 3px;

    &:after {
      content: "";
      position: absolute;
      display: block;
    }

    &.tooltip {
      &-top-left,
      &-top-right {
        bottom: 100%;
        margin-bottom: 5px;

        &:after {
          top: 100%;
          border-top: 4px solid rgba(0,0,0,.9);
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
        }
      }

      &-bottom-left,
      &-bottom-right {
        top: 100%;
        margin-top: 5px;

        &:after {
          bottom: 100%;
          border-bottom: 4px solid rgba(0,0,0,.9);
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
        }
      }

      &-top-left,
      &-bottom-left {
        left: 0;

        &:after {
          left: 16px;
        }
      }

      &-top-right,
      &-bottom-right {
        right: 0;

        &:after {
          right: 16px;
        }
      }

      &-left,
      &-right {
        top: 50%;
        transform: translateY(-50%);

        &:after {
          top: 50%;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
          transform: translateY(-50%);
        }
      }

      &-left {
        right: 100%;
        margin-right: 5px;

        &:after {
          right: -4px;
          border-left: 4px solid rgba(0,0,0,.9);
        }
      }

      &-right {
        left: 100%;
        margin-left: 5px;

        &:after {
          left: -4px;
          border-right: 4px solid rgba(0,0,0,.9);
        }
      }
    }
  }
}

input,
textarea {
  &:focus {
    & + .u-tooltip--v1 {
      opacity: 1;
    }
  }
}