/*--------------------------------------------------
  Info Block v7-1
----------------------------------------------------*/

.u-info-v7-1__item {
	border: 2px solid transparent;
	transition: all .2s ease-in-out;
	
	&-child-v1 {
		border: 5px solid transparent;
		transition: all .2s ease-in-out;
	}

	&-child-v2 {
		transition: all .2s ease-in-out;
	}
}

.u-info-v7-1:hover {
	border-color: $g-color-gray-light-v4;
	
	.u-info-v7-1__item {
		border-color: $g-color-gray-light-v3;
	}
	
	.u-info-v7-1__item-child-v1 {
		border-color: $g-color-gray-light-v3;
	}

	.u-info-v7-1__item-child-v2 {
		color: $g-color-white;
		background: $g-color-primary
	}
}