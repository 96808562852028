/*--------------------------------------------------
  Info Block v9-1
----------------------------------------------------*/

@media (min-width: $g-md) {
  .u-info-v9-1 {
    position: relative;

    &::before {
      position: absolute;
      top: 111px;
      left: 17%;
      width: 66%;
      border-top: 1px dotted $g-color-gray-light-v3;
      content: " ";
    }
  }
}