/*------------------------------------
  Checkboxes v2
------------------------------------*/

.u-check {
  &-icon {
    &-checkbox,
    &-radio {
      // v2
      &-v2 {
        width: 20px;
        height: 20px;
        border: solid 1px $g-color-primary;
      }
      // End v2
    }

    &-checkbox {
      // v2
      &-v2 {
        border-radius: 1px;
      }
      // End v2
    }

    &-radio {
      // v2
      &-v2 {
        border-radius: 50%;
      }
      // End v2
    }
  }
}