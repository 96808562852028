/*------------------------------------
  Background Colors
------------------------------------*/
/* Basic Colors
------------------------------------*/
/* Main Colors */
.g-bg-main {
  background-color: $g-bg-color-main !important;
}

/* Main Secondary */
.g-bg-secondary {
  background-color: $g-bg-color-secondary !important;
}

/* Primary Colors */
.g-bg-primary {
  background-color: $g-color-primary !important;

  // Hover
  &--hover:hover {
    background-color: $g-color-primary !important;
  }

  .u-block-hover:hover &--hover {
    background-color: $g-color-primary;
  }

  &--parent-hover {
    .g-parent:hover & {
      background-color: $g-color-primary !important;
    }
  }

  // Active
  &--active {
    &.active,
    .active & {
      background-color: $g-color-primary !important;
    }
  }

  &--parent-active {
    .g-parent.active & {
      background-color: $g-color-primary !important;
    }
  }

  //Pseudo-elements background color
  &--before::before,
  &--after::after {
    background-color: $g-color-primary !important;
  }

  // Primary dark
  &-dark {
    &-v1 {
      background-color: $g-color-primary-dark-v1 !important;
    }
    &-v2 {
      background-color: $g-color-primary-dark-v2 !important;
    }
    &-v3 {
      background-color: $g-color-primary-dark-v3 !important;
    }
  }

  // Primary opacity
  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-primary, .1) !important;
    }
    &-0_2 {
      background-color: rgba($g-color-primary, .2) !important;

      &--hover:hover {
        background-color: rgba($g-color-primary, .2) !important;
      }

      &--hover--after:hover::after {
        background-color: rgba($g-color-primary, .2) !important;
      }

      &--active {
        .active & {
          background-color: rgba($g-color-primary, .2) !important;
        }
      }

      &--before::before,
      &--after::after {
        background-color: rgba($g-color-primary, .2) !important;
      }
    }
    &-0_3 {
      background-color: rgba($g-color-primary, .3) !important;

      &--hover:hover {
        background-color: rgba($g-color-primary, .3) !important;
      }

      &--hover--after:hover::after {
        background-color: rgba($g-color-primary, .3) !important;
      }

      &--active {
        .active & {
          background-color: rgba($g-color-primary, .3) !important;
        }
      }

      &--before::before,
      &--after::after {
        background-color: rgba($g-color-primary, .3) !important;
      }
    }

    &-0_4 {
      background-color: rgba($g-color-primary, .4) !important;

      &--hover:hover {
        background-color: rgba($g-color-primary, .4) !important;
      }
      &--hover--after:hover::after {
        background-color: rgba($g-color-primary, .4) !important;
      }
      &--after::after {
        background-color: rgba($g-color-primary, .4) !important;
      }
    }
    &-0_6 {
      background-color: rgba($g-color-primary, .6) !important;

      &--hover:hover {
        background-color: rgba($g-color-primary, .6) !important;
      }
      &--hover--after:hover::after {
        background-color: rgba($g-color-primary, .6) !important;
      }
      &--after::after {
        background-color: rgba($g-color-primary, .6) !important;
      }
    }
    &-0_8 {
      background-color: rgba($g-color-primary, .8) !important;

      &--hover:hover {
        background-color: rgba($g-color-primary, .8) !important;
      }
      &--hover--after:hover::after {
        background-color: rgba($g-color-primary, .8) !important;
      }

      &--before,
      &--after {
        &::after {
          background-color: rgba($g-color-primary, .8) !important;
        }

        &--hover:hover {
          &::after {
            background-color: rgba($g-color-primary, .8) !important;
          }
        }
      }
    }
    &-0_9 {
      background-color: rgba($g-color-primary, .9) !important;

      &--hover:hover {
        background-color: rgba($g-color-primary, .9) !important;
      }
      &--hover--after:hover::after {
        background-color: rgba($g-color-primary, .9) !important;
      }

      &--before,
      &--after {
        &::after {
          background-color: rgba($g-color-primary, .9) !important;
        }

        &--hover:hover {
          &::after {
            background-color: rgba($g-color-primary, .9) !important;
          }
        }
      }
    }
  }
}

/* Black Colors */
.g-bg-black {
  background-color: $g-color-black !important;

  &--hover:hover {
    background-color: $g-color-black !important;
  }

  &--after::after {
    background-color: $g-color-black !important;
  }

  // Black opacity
  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-black, .1) !important;

      &--after::after {
        background-color: rgba($g-color-black, .1) !important;
      }
    }
    &-0_2 {
      background-color: rgba($g-color-black, .2) !important;

      &--after::after {
        background-color: rgba($g-color-black, .2) !important;
      }
    }
    &-0_3 {
      background-color: rgba($g-color-black, .3) !important;

      &--hover {
        &:hover {
          background-color: rgba($g-color-black, .3) !important;
        }
      }

      &--after::after,
      &--before::before {
        background-color: rgba($g-color-black, .3) !important;
      }
      &--hover--after:hover::after {
        background-color: rgba($g-color-black, .3) !important;
      }
    }
    &-0_4 {
      background-color: rgba($g-color-black, .4) !important;

      &--after::after {
        background-color: rgba($g-color-black, .4) !important;
      }
    }
    &-0_5 {
      background-color: rgba($g-color-black, .5) !important;

      &--hover {
        &:hover {
          background-color: rgba($g-color-black, .5) !important;
        }
      }

      &--after::after {
        background-color: rgba($g-color-black, .5) !important;
      }
    }
    &-0_6 {
      background-color: rgba($g-color-black, .6) !important;

      &--hover:hover {
        background-color: rgba($g-color-black, .7) !important;
      }

      &--after::after {
        background-color: rgba($g-color-black, .6) !important;
      }
    }
    &-0_7 {
      background-color: rgba($g-color-black, .7) !important;

      &--hover:hover {
        background-color: rgba($g-color-black, .7) !important;
      }

      &--after::after {
        background-color: rgba($g-color-black, .7) !important;
      }
    }
    &-0_8 {
      background-color: rgba($g-color-black, .8) !important;

      &--after::after {
        background-color: rgba($g-color-black, .8) !important;
      }

      &--hover {
        &:hover {
          background-color: rgba($g-color-black, .8) !important;
        }
      }
    }
    &-0_9 {
      background-color: rgba($g-color-black, .9) !important;

      &--after::after {
        background-color: rgba($g-color-black, .9) !important;
      }

      &--hover {
        &:hover {
          background-color: rgba($g-color-black, .9) !important;
        }
      }
    }
  }
}

.u-block-hover:hover .g-bg-black--hover {
  background-color: $g-color-black !important;
}

/* White Colors */
.g-bg-white {
  background-color: $g-color-white !important;

  //Pseudo-elements background color
  &--before::before,
  &--after::after {
    background-color: $g-color-white !important;
  }

  &--hover:hover,
  &--active.active {
    background-color: $g-color-white !important;
  }

  &--parent-hover {
    .g-parent:hover & {
      background-color: $g-color-white !important;
    }
  }

  &--disabled:disabled {
    background-color: $g-color-white !important;
  }

  //Pseudo-elements background color
  &--before::before,
  &--after::after {
    background-color: $g-color-white !important;
  }

  // White opacity
  &-opacity {
    &-0--after {
      &--parent-hover::after {
        .g-parent:hover & {
          background-color: rgba($g-color-white, 0) !important;
        }
      }
    }

    &-0_05 {
      background-color: rgba($g-color-white, .05) !important;
    }
    &-0_1 {
      background-color: rgba($g-color-white, .1) !important;

      &--after::after {
        background-color: rgba($g-color-white, .1) !important;
      }
    }
    &-0_2 {
      background-color: rgba($g-color-white, .2) !important;

      &--hover:hover {
        background-color: rgba($g-color-white, .2) !important;
      }
    }
    &-0_3 {
      background-color: rgba($g-color-white, .3) !important;

      &--hover:hover {
        background-color: rgba($g-color-white, .3) !important;
      }

      &--after::after {
        background-color: rgba($g-color-white, .3) !important;
      }
    }
    &-0_4 {
      background-color: rgba($g-color-white, .4) !important;

      &--hover:hover {
        background-color: rgba($g-color-white, .4) !important;
      }
    }
    &-0_5 {
      background-color: rgba($g-color-white, .5) !important;

      &--after::after {
        background-color: rgba($g-color-white, .5) !important;
      }
    }
    &-0_7 {
      background-color: rgba($g-color-white, .7) !important;

      &--hover:hover {
        background-color: rgba($g-color-white, .7) !important;
      }

      &--after::after {
        background-color: rgba($g-color-white, .7) !important;
      }
    }
    &-0_8 {
      background-color: rgba($g-color-white, .8) !important;

      &--hover:hover {
        background-color: rgba($g-color-white, .8) !important;
      }

      &--after::after {
        background-color: rgba($g-color-white, .8) !important;
      }

      &--hover--after:hover::after {
        background-color: rgba($g-color-primary, .8) !important;
      }
    }
    &-0_9 {
      background-color: rgba($g-color-white, .9) !important;

      &--hover:hover {
        background-color: rgba($g-color-white, .9) !important;
      }

      &--after::after {
        background-color: rgba($g-color-white, .9) !important;
      }
    }
  }
}

/* Gray Colors */
.g-bg-gray {
  // Dark Gray
  &-dark {
    &-v1 {
      background-color: $g-color-gray-dark-v1 !important;

      &--hover:hover {
        background-color: $g-color-gray-dark-v1 !important;
      }
    }
    &-v2 {
      background-color: $g-color-gray-dark-v2 !important;

      &--hover:hover {
        background-color: $g-color-gray-dark-v2 !important;
      }
    }
    &-v3 {
      background-color: $g-color-gray-dark-v3 !important;

      &--hover:hover {
        background-color: $g-color-gray-dark-v3 !important;
      }
    }
    &-v4 {
      background-color: $g-color-gray-dark-v4 !important;

      &--hover:hover {
        background-color: $g-color-gray-dark-v4 !important;
      }
    }
    &-v5 {
      background-color: $g-color-gray-dark-v5 !important;

      &--hover:hover {
        background-color: $g-color-gray-dark-v5 !important;
      }
    }
  }

  // Light Gray
  &-light {
    &-v1 {
      background-color: $g-color-gray-light-v1 !important;

      &--hover:hover {
        background-color: $g-color-gray-light-v1 !important;
      }
    }
    &-v2 {
      background-color: $g-color-gray-light-v2 !important;

      &--hover:hover {
        background-color: $g-color-gray-light-v2 !important;
      }
    }
    &-v3 {
      background-color: $g-color-gray-light-v3 !important;

      &--hover:hover {
        background-color: $g-color-gray-light-v3 !important;
      }
    }
    &-v4 {
      background-color: $g-color-gray-light-v4 !important;

      &--hover:hover {
        background-color: $g-color-gray-light-v4 !important;
      }
    }
    &-v5 {
      background-color: $g-color-gray-light-v5 !important;

      &--hover:hover {
        background-color: $g-color-gray-light-v5 !important;
      }

      // Active
      &--active {
        &.active,
        .active & {
          background-color: $g-color-gray-light-v5 !important;
        }
      }
    }
  }
}

/* Transparent */
.g-bg-transparent {
  background-color: transparent !important;

  &--hover:hover {
    background-color: transparent !important;
  }

  &--hover--after:hover::after {
    background-color: transparent !important;
  }

  &--parent-hover {
    .g-parent:hover & {
      background-color: transparent !important;
    }
  }
}

/* Complementary Colors
------------------------------------*/
/* Beige Colors */
.g-bg-beige {
  background-color: $g-color-beige !important;
}

/* Color Green */
.g-bg-green {
  background-color: $g-color-green !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-green, .1) !important; // R
    }
  }

  &--hover:hover {
    background-color: $g-color-green !important; // J
  }
}

/* Color Blue */
.g-bg-blue {
  background-color: $g-color-blue !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-blue, .1) !important; // R
    }
    &-0_7 {
      background-color: rgba($g-color-blue, .7) !important; // J
    }
    &-0_9 {
      background-color: rgba($g-color-blue, .9) !important; // Z
    }
  }

  &--hover:hover {
    background-color: $g-color-blue !important; // J
  }
}

/* Color Light Blue */
.g-bg-lightblue {
  background-color: $g-color-lightblue !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-lightblue, .1) !important; // R
    }
  }
}

.g-bg-lightblue-v1 {
  background-color: $g-color-lightblue-v1 !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-lightblue-v1, .1) !important; // R
    }
  }
}

/* Color Dark Blue */
.g-bg-darkblue {
  background-color: $g-color-darkblue !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-darkblue, .1) !important; // R
    }
  }
}

/* Color Indigo */
.g-bg-indigo {
  background-color: $g-color-indigo !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-indigo, .1) !important; // R
    }
  }
}

/* Color Red */
.g-bg-red {
  background-color: $g-color-red !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-red, .1) !important; // R
    }
    &-0_2 {
      background-color: rgba($g-color-red, .2) !important; // O
    }
    &-0_5 {
      background-color: rgba($g-color-red, .5) !important; // J
    }
    &-0_8 {
      background-color: rgba($g-color-red, .8) !important; // P
    }
  }

  &--hover:hover {
    background-color: $g-color-red !important; // J
  }
}

/* Color Light Red */
.g-bg-lightred {
  background-color: $g-color-lightred !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-lightred, .1) !important; // R
    }
  }

  &--hover:hover {
    background-color: $g-color-lightred !important; // Z
  }
}

/* Color Dark Red */
.g-bg-darkred {
  background-color: $g-color-darkred !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-darkred, .1) !important; // R
    }
  }
}

/* Color Purple */
.g-bg-purple {
  background-color: $g-color-purple !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-purple, .1) !important; // R
    }
    &-0_7 {
      background-color: rgba($g-color-purple, .7) !important; // P
    }
    &-0_9 {
      background-color: rgba($g-color-purple, .9) !important; // Z

      &--after::after {
        background-color: rgba($g-color-purple, .9) !important; // J
      }
    }
  }

  &--hover:hover {
    background-color: $g-color-purple !important; // J
  }
}

/* Color Dark Purple */
.g-bg-darkpurple {
  background-color: $g-color-darkpurple !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-darkpurple, .1) !important; // R
    }
  }
}

/* Color Pink */
.g-bg-pink {
  background-color: $g-color-pink !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-pink, .1) !important; // R
    }
    &-0_9 {
      background-color: rgba($g-color-pink, .9) !important; // Z
    }
  }

  &--hover:hover {
    background-color: $g-color-pink !important; // J
  }

  &--before,
  &--after {
    &::after {
      background-color: $g-color-pink !important;
    }

    &--hover:hover {
      &::after {
        background-color: $g-color-pink !important;
      }
    }
  }
}

/* Color Orange */
.g-bg-orange {
  background-color: $g-color-orange !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-orange, .1) !important; // R
    }
  }

  &-opacity {
    &-0_2 {
      background-color: rgba($g-color-orange, .2) !important; // O
    }
  }

  &--hover:hover {
    background-color: $g-color-orange !important; // J
  }
}

/* Color Deep Orange */
.g-bg-deeporange {
  background-color: $g-color-deeporange !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-deeporange, .1) !important; // R
    }
  }

  &--hover:hover {
    background-color: $g-color-deeporange !important; // J
  }
}

/* Color Yellow */
.g-bg-yellow {
  background-color: $g-color-yellow !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-yellow, .1) !important; // R
    }
    &-0_7 {
      background: rgba($g-color-yellow, .7) !important; // J
    }
  }

  &--hover:hover {
    background-color: $g-color-yellow !important; // J
  }
}

/* Color Aqua */
.g-bg-aqua {
  background-color: $g-color-aqua !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-aqua, .1) !important; // R
    }
    &-0_9 {
      background-color: rgba($g-color-aqua, .9) !important; // Z
    }
  }

  &--hover:hover {
    background-color: $g-color-aqua !important; // J
  }
}

/* Color Cyan */
.g-bg-cyan {
  background-color: $g-color-cyan !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-cyan, .1) !important; // R
    }

    &-0_9 {
      background-color: rgba($g-color-cyan, .9) !important;

      &--after::after {
        background-color: rgba($g-color-cyan, .9) !important; // J
      }
    }
  }

  &--hover:hover {
    background-color: $g-color-cyan !important; // J
  }
}

/* Color Teal */
.g-bg-teal {
  background-color: $g-color-teal !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-teal, .1) !important; // R
    }

    &-0_9 {
      background-color: rgba($g-color-teal, .9) !important; // J

      &--after::after {
        background-color: rgba($g-color-teal, .9) !important; // J
      }
    }
  }

  &--hover:hover {
    background-color: $g-color-teal !important; // J
  }
}

/* Color Brown */
.g-bg-brown {
  background-color: $g-color-brown !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-brown, .1) !important; // R
    }
  }

  &--hover:hover {
    background-color: $g-color-brown !important; // Z
  }
}

/* Color Blue Gray */
.g-bg-bluegray {
  background-color: $g-color-bluegray !important;

  &-opacity {
    &-0_1 {
      background-color: rgba($g-color-bluegray, .1) !important; // R
    }
    &-0_2 {
      &--before::before,
      &--after::after {
        background-color: rgba($g-color-bluegray, .2) !important; // Z
      }
    }
    &-0_3 {
      &--before::before,
      &--after::after {
        background-color: rgba($g-color-bluegray, .3) !important; // Z
      }
    }
    &-0_5,
    &-0_5--after::after {
      background-color: rgba($g-color-bluegray, .5) !important;
    }
    &-0_7 {
      background-color: rgba($g-color-bluegray, .7) !important; // Z
    }
  }

  &--hover:hover {
    background-color: $g-color-bluegray !important; // J
  }
}

//Grayscale
.g-grayscale {
  &-100x {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
    filter: gray;
    -webkit-filter: grayscale(100%);
  }

  &-0 {
    &--parent-hover {
      .g-parent:hover & {
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
        -webkit-filter: grayscale(0%);
      }
    }

    &--hover:hover {
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
      -webkit-filter: grayscale(0%);
    }
  }
}

/* O */
@media (min-width: $g-sm) {
  .g-bg-transparent--sm {
    background-color: transparent !important;
  }
}
