//
// Zigzag
//

@mixin zigzag($zigzag-class-name, $zigzag-pseudo-el, $zigzag-bg-color, $zigzag-position, $zigzag-size) {
  .#{$zigzag-class-name} {
    position: relative;
    background: $zigzag-bg-color;
    z-index: 1;

    &::#{$zigzag-pseudo-el} {
      content: "";
      position: absolute;
      @if ($zigzag-position == top) {
        bottom: 100%;
      } @else {
        top: 100%;
      }
      left: 0;
      right: 0;
      display: block;
      height: $zigzag-size;
      @if ($zigzag-position == top) {
        background: -webkit-linear-gradient(-225deg, $zigzag-bg-color $zigzag-size/2, transparent 0) 0 $zigzag-size/2, -webkit-linear-gradient(-315deg, $zigzag-bg-color $zigzag-size/2, transparent 0) 0 $zigzag-size/2;
      } @else {
        background: -webkit-linear-gradient(225deg, $zigzag-bg-color $zigzag-size/2, transparent 0) 0 $zigzag-size/2, -webkit-linear-gradient(315deg, $zigzag-bg-color $zigzag-size/2, transparent 0) 0 $zigzag-size/2;
      }
      background: {
        position: top left;
        repear: repeat-x;
        size: $zigzag-size $zigzag-size;
      }
    }
  }
}