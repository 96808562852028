/*------------------------------------
  Focus state border
------------------------------------*/

/* Primary Colors */
.g-brd-primary {
  &--focus:focus,
  &--focus.g-state-focus *,
  &--focus.g-state-focus *:focus {
    border-color: $g-color-primary !important;
  }
}

/* Black Colors */
.g-brd-black {
  &--focus:focus,
  &--focus.g-state-focus *,
  &--focus.g-state-focus *:focus {
    border-color: $g-color-black !important;
  }
}

/* White */
.g-brd-white {
  &--focus:focus,
  &--focus.g-state-focus *,
  &--focus.g-state-focus *:focus {
    border-color: $g-color-white !important;
  }
}

/* Gray Colors */
.g-brd-gray {
  // Dark Gray
  &-dark {
    &-v1 {
      &--focus:focus,
      &--focus.g-state-focus *,
      &--focus.g-state-focus *:focus {
        border-color: $g-color-gray-dark-v1 !important;
      }
    }

    &-v2 {
      &--focus:focus,
      &--focus.g-state-focus *,
      &--focus.g-state-focus *:focus {
        border-color: $g-color-gray-dark-v2 !important;
      }
    }

    &-v3 {
      &--focus:focus,
      &--focus.g-state-focus *,
      &--focus.g-state-focus *:focus {
        border-color: $g-color-gray-dark-v3 !important;
      }
    }

    &-v4 {
      &--focus:focus,
      &--focus.g-state-focus *,
      &--focus.g-state-focus *:focus {
        border-color: $g-color-gray-dark-v4 !important;
      }
    }

    &-v5 {
      &--focus:focus,
      &--focus.g-state-focus *,
      &--focus.g-state-focus *:focus {
        border-color: $g-color-gray-dark-v5 !important;
      }
    }

    // Light Gray
    &-light {
      &-v1 {
        &--focus:focus,
        &--focus.g-state-focus *,
        &--focus.g-state-focus *:focus {
          border-color: $g-color-gray-light-v1 !important;
        }
      }

      &-v2 {
        &--focus:focus,
        &--focus.g-state-focus *,
        &--focus.g-state-focus *:focus {
          border-color: $g-color-gray-light-v2 !important;
        }
      }

      &-v3 {
        &--focus:focus,
        &--focus.g-state-focus *,
        &--focus.g-state-focus *:focus {
          border-color: $g-color-gray-light-v3 !important;
        }
      }

      &-v4 {
        &--focus:focus,
        &--focus.g-state-focus *,
        &--focus.g-state-focus *:focus {
          border-color: $g-color-gray-light-v4 !important;
        }
      }

      &-v5 {
        &--focus:focus,
        &--focus.g-state-focus *,
        &--focus.g-state-focus *:focus {
          border-color: $g-color-gray-light-v5 !important;
        }
      }
    }
  }
}

/* Transparent */
.g-brd-transparent {
  &--focus:focus,
  &--focus.g-state-focus *,
  &--focus.g-state-focus *:focus {
    border-color: transparent !important;
  }
}

/* Color Red */
.g-brd-red {
  &--focus:focus,
  &--focus.g-state-focus *,
  &--focus.g-state-focus *:focus {
    border-color: $g-color-red !important;
  }
}
