/*------------------------------------
  Material: Floating
------------------------------------*/
.u-btn-floating {
  @include px-to-rem(width, 40px);
  @include px-to-rem(height, 40px);
  padding: 0;
  border-radius: 50%;
  vertical-align: middle;
  overflow: hidden;
  @include px-to-rem(line-height, 40px);

  i {
    font-size: 1.6rem;
    @include px-to-rem(line-height, 40px - 2px);
  }

  &.u-halfway-fab {
    position: absolute;
    @include px-to-rem(right, 24px);
    @include px-to-rem(bottom, -20px);

    &--left {
      right: auto;
      @include px-to-rem(left, 24px);
    }
  }
  &--large {
    @include px-to-rem(width, 56px);
    @include px-to-rem(height, 56px);
    @include px-to-rem(line-height, 56px);

    i {
      font-size: 1.6rem;
      @include px-to-rem(line-height, 56px - 2px);
    }

    &.u-halfway-fab {
      @include px-to-rem(bottom, -28px);
    }
  }
}

// button fix
button.u-btn-floating {
  border: none
}