/*------------------------------------
  Block Hovers
------------------------------------*/

//
// Common Styles
//

[class*="u-block-hover"] {
  position: relative;
  max-width: 100%;
  margin: 0;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);

  &,
  &::before,
  &::after {
    transition: all .3s ease;
  }
}

.u-block-hover--uncroped {
  overflow: visible;
}

.u-block-hover--uncroped:hover {
  z-index: 2;
}

.u-block-hover__img {
  max-width: 100%;
  vertical-align: top;
}

.u-block-hover__block {
  min-height: 100%;
}

[class*="u-block-hover__additional"] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

[class*="u-block-hover__additional--v1"] { // Z
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 15px;
  opacity: 0;
}



//
// Opacity // Z
//

.u-block-hover:hover .u-block-hover__additional--v1 {
  opacity: 1;
}



//
// Box-shadow
//

.u-block-hover--shadow {
  &:hover,
  &:hover .u-block-hover__additional-shadow {
    box-shadow: 0 0 10px $g-color-gray-dark-v4;
  }

  &-v2:hover,
  &-v2:hover .u-block-hover__additional-shadow {
    box-shadow: 0 0 15px $g-color-gray-light-v3;
  }

  &-v3:hover,
  &-v3:hover .u-block-hover__additional-shadow {
    box-shadow: 0 0 3px $g-color-gray-light-v3;
  }
}



//
// Scale
//

.u-block-hover--scale:hover,
.u-block-hover--scale-img:hover .u-block-hover__img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.u-block-hover__additional-scale {
  z-index: -1;
  max-width: initial;
}

.u-block-hover:hover .u-block-hover__additional-scale {
  top: -20px;
  right: -20px;
  bottom: -20px;
  left: -20px;
}

.u-block-hover--scale-down:hover,
.u-block-hover--scale-down-img:hover .u-block-hover__img {
  transform: scale3d(.9, .9, .9);
}

.u-block-hover__additional-scale-down {
  z-index: -1;
  max-width: initial;
}

.u-block-hover:hover .u-block-hover__additional-scale-down {
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
}



//
// Zoom v1
//

.u-block-hover__main--zoom-v1,
.u-block-hover__main--zoom-v2 {
  // Improvement flashing issue for Chrome browser
  -webkit-transform: scale(1.01);
}

.u-block-hover:hover .u-block-hover__main--zoom-v1 {
  transform: scale(1.1);
}

.u-block-hover:hover .u-block-hover__main--zoom-v2 {
  transform: scale(1.04);
}

// temperory solution for icons (cut issue)
// relative position issue with backface-visibility
.u-block-hover [class*="icon-"] {
  padding: 1px;
}


//
// Filters
//

// Grayscale
.u-block-hover__main--grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
  filter: gray;
  -webkit-filter: grayscale(100%);
}
.u-block-hover:hover .u-block-hover__main--grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}

// White
.u-block-hover__main--white {
  filter: brightness(0) invert(1);
}



//
// Blur
//

.u-block-hover__additional--blur {
  opacity: 0;

.u-block-hover:hover & {
  opacity: 1;
  transition-delay: .11s;
}
}
.u-block-hover:hover .u-block-hover__main--blur {
  filter: blur(30px);
  transform: scale(1.2);
  opacity: 0;
}



//
// Overlays
//

[class*="u-block-hover-image-overlay"]::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all .35s ease;
}
.u-block-hover:hover .u-block-hover-image-overlay::after {
  opacity: 1;
}



//
// Fade
//

.u-block-hover__additional--fade {
  opacity: 0;

  .u-block-hover:hover & {
    opacity: 1;
    transform: translate(0, 0);
  }

  // Fade up
  &-up {
    transform: translate(0, 15%);
  }

  // Fade down
  &-down {
    transform: translate(0, -15%);
  }

  // Fade left
  &-left {
    transform: translate(-15%, 0);
  }

  // Fade right
  &-right {
    transform: translate(15%, 0);
  }
}



//
// Push
//


.u-block-hover:hover [class*="u-block-hover__additional--push"] {
  transform: translate(0, 0);
}

// Push up
.u-block-hover:hover .u-block-hover__main--push-up {
  transform: translateY(-100%);
}
.u-block-hover__additional--push-up {
  transform: translateY(100%);
}

// Push down
.u-block-hover:hover .u-block-hover__main--push-down {
  transform: translateY(100%);
}
.u-block-hover__additional--push-down {
  transform: translateY(-100%);
}

// Push left
.u-block-hover:hover .u-block-hover__main--push-left {
  transform: translateX(-100%);
}
.u-block-hover__additional--push-left {
  transform: translateX(100%);
}

// Push right
.u-block-hover:hover .u-block-hover__main--push-right {
  transform: translateX(100%);
}
.u-block-hover__additional--push-right {
  transform: translateX(-100%);
}



//
// Slide
//

.u-block-hover:hover [class*="u-block-hover__additional--slide"],
.u-block-hover:hover [class*="u-block-hover__additional--partially-slide"] {
  transform: translate(0, 0);
}

// Slide up
.u-block-hover__additional--slide-up {
  transform: translateY(100%);
}

// Slide up
.u-block-hover__additional--slide-down {
  transform: translateY(-100%);
}

// Slide left
.u-block-hover__additional--slide-left {
  transform: translateX(100%);
}

// Slide right
.u-block-hover__additional--slide-right {
  transform: translateX(-100%);
}

// Slide bottom-right
.u-block-hover__additional--slide-bottom-right {
  transform: translate(-100%, -100%);
}

// Slide bottom-left
.u-block-hover__additional--slide-bottom-left {
  transform: translate(100%, -100%);
}

// Slide top-right
.u-block-hover__additional--slide-top-right {
  transform: translate(-100%, 100%);
}

// Slide top-left
.u-block-hover__additional--slide-top-left {
  transform: translate(100%, 100%);
}

// Partially slide-up
.u-block-hover__additional--partially-slide-up {
  top: auto;
  transform: translate3d(0, 100%, 0);
  overflow: visible;

  .u-block-hover__visible {
    position: absolute;
    bottom: 100%;
    margin-bottom: -1px;
    left: 0;
    right: 0;
    padding-left: inherit;
    padding-right: inherit;
    background: inherit;
  }
}

// Partially slide-down
.u-block-hover__additional--partially-slide-down {
  bottom: auto;
  transform: translate3d(0, -100%, 0);
  overflow: visible;

  .u-block-hover__visible {
    position: absolute;
    top: 100%;
    margin-top: -1px;
    left: 0;
    right: 0;
    padding-left: inherit;
    padding-right: inherit;
    background: inherit;
  }
}



//
// Cot
//

.u-block-hover--cot {
  perspective: 50em;
}
[class*="u-block-hover__additional--cot"] {
  opacity: 0;
  z-index: 1;
}
.u-block-hover--cot:hover [class*="u-block-hover__main--cot"] {
  opacity: 0;
}
.u-block-hover--cot:hover [class*="u-block-hover__additional--cot"] {
  opacity: 1;
  transition-delay: 0.21s;
}

// Cot up
.u-block-hover__main--cot-up {
  transform-origin: 50% 0;
}
.u-block-hover__additional--cot-up {
  transform: rotateX(90deg);
  transform-origin: 50% 100%;
}
.u-block-hover--cot:hover .u-block-hover__main--cot-up {
  transform: rotateX(-90deg);
}
.u-block-hover--cot:hover .u-block-hover__additional--cot-up {
  transform: rotateX(0deg);
}

// Cot down
.u-block-hover__main--cot-down {
  transform-origin: 50% 100%;
}
.u-block-hover__additional--cot-down {
  transform: rotateX(-90deg);
  transform-origin: 50% -50%;
}
.u-block-hover--cot:hover .u-block-hover__main--cot-down {
  transform: rotateX(90deg);
  opacity: 0;
}
.u-block-hover--cot:hover .u-block-hover__additional--cot-down {
  transform: rotateX(0deg);
}

// Cot left
.u-block-hover__main--cot-left {
  transform-origin: 0% 50%;
}
.u-block-hover__additional--cot-left {
  transform: rotateY(-90deg);
  transform-origin: 100% 50%;
}
.u-block-hover--cot:hover .u-block-hover__main--cot-left {
  transform: rotateY(90deg);
}
.u-block-hover--cot:hover .u-block-hover__additional--cot-left {
  transform: rotateY(0deg);
}

// Cot right
.u-block-hover__main--cot-right {
  transform-origin: 100% 50%;
}
.u-block-hover__additional--cot-right {
  transform: rotateY(90deg);
  transform-origin: 0 50%;
}
.u-block-hover--cot:hover .u-block-hover__main--cot-right {
  transform: rotateY(-90deg);
}
.u-block-hover--cot:hover .u-block-hover__additional--cot-right {
  transform: rotateY(0deg);
}



//
// Flip
//

.u-block-hover--flip {
  perspective: 50em;
}
[class*="u-block-hover__main--flip"] {
  backface-visibility: hidden;
}
[class*="u-block-hover__additional--flip"] {
  opacity: 0;
}
.u-block-hover--flip:hover [class*="u-block-hover__main--flip"] {
  opacity: 0;
}
.u-block-hover--flip:hover [class*="u-block-hover__additional--flip"] {
  opacity: 1;
  transition-delay: 0.13999999999999999s;
}

// Flip horizontal
.u-block-hover__additional--flip-horiz {
  transform: rotateX(90deg);
  transform-origin: 0 50%;
}
.u-block-hover--flip:hover .u-block-hover__main--flip-horiz {
  transform: rotateX(-180deg);
}
.u-block-hover--flip:hover .u-block-hover__additional--flip-horiz {
  transform: rotateX(0deg);
}

// Flip vertical
.u-block-hover__additional--flip-vert {
  transform: rotateY(90deg);
  transform-origin: 50% 0%;
}
.u-block-hover--flip:hover .u-block-hover__main--flip-vert {
  transform: rotateY(-180deg);
}
.u-block-hover--flip:hover .u-block-hover__additional--flip-vert {
  transform: rotateY(0deg);
}

// Flip diagonal-1
.u-block-hover__additional--flip-diag-1 {
  transform: rotate3d(1, 1, 0, 100deg);
}
.u-block-hover--flip:hover .u-block-hover__main--flip-diag-1 {
  transform: rotate3d(-1, -1, 0, 100deg);
}
.u-block-hover--flip:hover .u-block-hover__additional--flip-diag-1 {
  transform: rotate3d(0, 0, 0, 0deg);
}

// Flip diagonal-2
.u-block-hover__additional--flip-diag-2 {
  transform: rotate3d(1, -1, 0, 100deg);
}
.u-block-hover--flip:hover .u-block-hover__main--flip-diag-2 {
  transform: rotate3d(-1, 1, 0, 100deg);
}
.u-block-hover--flip:hover .u-block-hover__additional--flip-diag-2 {
  transform: rotate3d(0, 0, 0, 0deg);
}



//
// Fold
//

.u-block-hover--fold {
  perspective: 50em;
}
[class*="u-block-hover__main--fold"] {
  transform-origin: 50% 0;
}
[class*="u-block-hover__additional--fold"] {
  z-index: 1;
  opacity: 0;
}
.u-block-hover--fold:hover [class*="u-block-hover__main--fold"] {
  opacity: 0;
  transition-delay: 0;
}
.u-block-hover--fold:hover [class*="u-block-hover__additional--fold"] {
  transform: rotateX(0deg) translate3d(0, 0, 0) scale(1);
  opacity: 1;
  transition-delay: .21s;
}

// Flip-up
.u-block-hover__main--fold-up {
  transform-origin: 50% 0;
}
.u-block-hover__additional--fold-up {
  transform: rotateX(-90deg) translate3d(0, -50%, 0) scale(.6);
  transform-origin: 50% 100%;
}
.u-block-hover--fold:hover .u-block-hover__main--fold-up {
  transform: rotateX(90deg) scale(.6) translateY(50%);
}

// Flip-down
.u-block-hover__main--fold-down {
  transform-origin: 50% 100%;
}
.u-block-hover__additional--fold-down {
  transform: rotateX(90deg) translate3d(0, 50%, 0) scale(.6);
  transform-origin: 50% 0;
}
.u-block-hover--fold:hover .u-block-hover__main--fold-down {
  transform: rotateX(-90deg) scale(.6) translateY(-50%);
}

// Flip-left
.u-block-hover__main--fold-left {
  transform-origin: 0 50%;
}
.u-block-hover__additional--fold-left {
  transform: rotateY(90deg) translate3d(-50%, 0, 0) scale(.6);
  transform-origin: 100% 50%;
}
.u-block-hover--fold:hover .u-block-hover__main--fold-left {
  transform: rotateY(-90deg) scale(.6) translateX(50%);
}

// Flip-right
.u-block-hover__main--fold-right {
  transform-origin: 100% 50%;
}
.u-block-hover__additional--fold-right {
  transform: rotateY(-90deg) translate3d(50%, 0, 0) scale(.6);
  transform-origin: 0 50%;
}
.u-block-hover--fold:hover .u-block-hover__main--fold-right {
  transform: rotateY(90deg) scale(.6) translateX(-50%);
}



//
// Zoom-in
//

.u-block-hover__additional--zoom-in {
  opacity: 0;
  transform: scale(.5);
}
.u-block-hover:hover .u-block-hover__additional--zoom-in {
  transform: scale(1);
  opacity: 1;
}



//
// Zoom-out
//

.u-block-hover__additional--zoom-out {
  transform: scale(.5);
  transform-origin: 50% 50%;
  opacity: 0;
}
.u-block-hover:hover .u-block-hover__main--zoom-out {
  transform: scale(.5);
  opacity: 0;
}
.u-block-hover:hover .u-block-hover__additional--zoom-out {
  transform: scale(1);
  opacity: 1;
  transition-delay: .35s;
}



//
// Shutter-out
//

[class*="u-block-hover--shutter-out"]::after {
  content: "";
  position: absolute;
  transition-delay: .105s;
  z-index: 1;
}
.u-block-hover__additional--shutter-out {
  opacity: 0;
  transition-delay: 0s;
  z-index: 2;
}
[class*="u-block-hover--shutter-out"]:hover::after {
  transition-delay: 0s;
}
[class*="u-block-hover--shutter-out"]:hover .u-block-hover__additional--shutter-out {
  opacity: 1;
  transition-delay: .105s;
}

// Shutter-out horizontal
.u-block-hover--shutter-out-horiz::after {
  left: 50%;
  right: 50%;
  top: 0;
  bottom: 0;
}
.u-block-hover--shutter-out-horiz:hover::after {
  left: 0;
  right: 0;
}

// Shutter-out vertical
.u-block-hover--shutter-out-vert::after {
  top: 50%;
  bottom: 50%;
  left: 0;
  right: 0;
}
.u-block-hover--shutter-out-vert:hover::after {
  top: 0;
  bottom: 0;
}

// Shutter-out diagonal-1
.u-block-hover--shutter-out-diag-1::after {
  top: 50%;
  bottom: 50%;
  left: -35%;
  right: -35%;
  transform: rotate(45deg);
}
.u-block-hover--shutter-out-diag-1:hover::after {
  top: -35%;
  bottom: -35%;
}

// Shutter-out diagonal-2
.u-block-hover--shutter-out-diag-2::after {
  top: 50%;
  bottom: 50%;
  left: -35%;
  right: -35%;
  transform: rotate(-45deg);
}
.u-block-hover--shutter-out-diag-2:hover::after {
  top: -35%;
  bottom: -35%;
}



//
// Shutter-in
//

[class*="u-block-hover--shutter-in"]::after,
[class*="u-block-hover--shutter-in"]::before {
  content: "";
  position: absolute;
  z-index: 1;
}
[class*="u-block-hover--shutter-in"]::after {
  top: 0;
  left: 0;
}
[class*="u-block-hover--shutter-in"]::before {
  right: 0;
  bottom: 0;
}
.u-block-hover__additional--shutter-in {
  opacity: 0;
  z-index: 2;
}
[class*="u-block-hover--shutter-in"]:hover .u-block-hover__additional--shutter-in {
  opacity: 1;
  transition-delay: .21s;
}

// Shutter-in horizontal
.u-block-hover--shutter-in-horiz::after,
.u-block-hover--shutter-in-horiz::before {
  width: 0;
  height: 100%;
}
.u-block-hover--shutter-in-horiz:hover::after,
.u-block-hover--shutter-in-horiz:hover::before {
  width: 100%;
}

// Shutter-in horizontal
.u-block-hover--shutter-in-vert::after,
.u-block-hover--shutter-in-vert::before {
  height: 0;
  width: 100%;
}
.u-block-hover--shutter-in-vert:hover::after,
.u-block-hover--shutter-in-vert:hover::before {
  height: 100%;
}

// Shutter-in diagonal-1
.u-block-hover--shutter-in-diag-1::after,
.u-block-hover--shutter-in-diag-1::before {
  width: 200%;
  height: 200%;
  transition: all .6s ease;
}
.u-block-hover--shutter-in-diag-1::after {
  transform: skew(-45deg) translateX(-150%);
}
.u-block-hover--shutter-in-diag-1::before {
  transform: skew(-45deg) translateX(150%);
}
.u-block-hover--shutter-in-diag-1:hover::after {
  transform: skew(-45deg) translateX(-50%);
}
.u-block-hover--shutter-in-diag-1:hover::before {
  transform: skew(-45deg) translateX(50%);
}

// Shutter-in diagonal-2
.u-block-hover--shutter-in-diag-2::after,
.u-block-hover--shutter-in-diag-2::before {
  width: 200%;
  height: 200%;
  transition: all .6s ease;
}
.u-block-hover--shutter-in-diag-2::after {
  transform: skew(45deg) translateX(-100%);
}
.u-block-hover--shutter-in-diag-2::before {
  transform: skew(45deg) translateX(100%);
}
.u-block-hover--shutter-in-diag-2:hover::after {
  transform: skew(45deg) translateX(0);
}
.u-block-hover--shutter-in-diag-2:hover::before {
  transform: skew(45deg) translateX(0);
}



//
// Shutter-in-out
//

[class*="u-block-hover--shutter-in-out"]::after,
[class*="u-block-hover--shutter-in-out"]::before {
  content: "";
  position: absolute;
  z-index: 1;
}
[class*="u-block-hover--shutter-in-out"]::after {
  top: 0;
  left: 0;
}
[class*="u-block-hover--shutter-in-out"]::before {
  right: 0;
  bottom: 0;
}
.u-block-hover__additional--shutter-in-out {
  opacity: 0;
  z-index: 2;
}
[class*="u-block-hover--shutter-in-out"]:hover .u-block-hover__additional--shutter-in-out {
  opacity: 1;
  transition-delay: .21s;
}

// Shutter-in-out horizontal
.u-block-hover--shutter-in-out-horiz::after,
.u-block-hover--shutter-in-out-horiz::before {
  width: 0;
  height: 100%;
}
.u-block-hover--shutter-in-out-horiz:hover::after,
.u-block-hover--shutter-in-out-horiz:hover::before {
  width: 100%;
  opacity: .75;
}

// Shutter-in-out horizontal
.u-block-hover--shutter-in-out-vert::after,
.u-block-hover--shutter-in-out-vert::before {
  height: 0;
  width: 100%;
}
.u-block-hover--shutter-in-out-vert:hover::after,
.u-block-hover--shutter-in-out-vert:hover::before {
  height: 100%;
  opacity: .75;
}

// Shutter-in-out diagonal-1
.u-block-hover--shutter-in-out-diag-1::after,
.u-block-hover--shutter-in-out-diag-1::before {
  width: 200%;
  height: 200%;
  transition: all .6s ease;
  opacity: .75;
}
.u-block-hover--shutter-in-out-diag-1::after {
  transform: skew(-45deg) translateX(-150%);
}
.u-block-hover--shutter-in-out-diag-1::before {
  transform: skew(-45deg) translateX(150%);
}
.u-block-hover--shutter-in-out-diag-1:hover::after {
  transform: skew(-45deg) translateX(-50%);
}
.u-block-hover--shutter-in-out-diag-1:hover::before {
  transform: skew(-45deg) translateX(50%);
}

// Shutter-in-out diagonal-2
.u-block-hover--shutter-in-out-diag-2::after,
.u-block-hover--shutter-in-out-diag-2::before {
  width: 200%;
  height: 200%;
  transition: all .6s ease;
  opacity: .75;
}
.u-block-hover--shutter-in-out-diag-2::after {
  transform: skew(45deg) translateX(-100%);
}
.u-block-hover--shutter-in-out-diag-2::before {
  transform: skew(45deg) translateX(100%);
}
.u-block-hover--shutter-in-out-diag-2:hover::after {
  transform: skew(45deg) translateX(0);
}
.u-block-hover--shutter-in-out-diag-2:hover::before {
  transform: skew(45deg) translateX(0);
}



//
// Strip shutter
//

.u-block-hover--strip-shutter::before,
.u-block-hover--strip-shutter::after,
.u-block-hover__additional--strip-shutter::before,
.u-block-hover__additional--strip-shutter::after {
  content: "";
  position: absolute;
  top: 0;
  width: 25%;
  height: 100%;
  transform: scaleY(0);
  opacity: 0;
}
.u-block-hover--strip-shutter::before,
.u-block-hover--strip-shutter::after {
  z-index: 1;
}
.u-block-hover--strip-shutter::before {
  left: 0;
  transition-delay: 0s;
}
.u-block-hover--strip-shutter::after {
  left: 25%;
  transition-delay: .105s;
}
.u-block-hover__additional--strip-shutter {
  z-index: 3;
}
.u-block-hover__additional--strip-shutter::before,
.u-block-hover__additional--strip-shutter::after {
  z-index: -1;
}
.u-block-hover__additional--strip-shutter::before {
  left: 50%;
  transition-delay: .21s;
}
.u-block-hover__additional--strip-shutter::after {
  left: 75%;
  transition-delay: .35s;
}
.u-block-hover--strip-shutter:hover::before,
.u-block-hover--strip-shutter:hover::after,
.u-block-hover--strip-shutter:hover .u-block-hover__additional--strip-shutter::before,
.u-block-hover--strip-shutter:hover .u-block-hover__additional--strip-shutter::after {
  transform: scale(1);
  opacity: 1;
}
.u-block-hover__additional--strip-shutter__inner {
  opacity: 0;
}
.u-block-hover--strip-shutter:hover .u-block-hover__additional--strip-shutter__inner {
  opacity: 1;
  transition-delay: .35s;
}



//
// Tile
//

.u-block-hover--tile::before,
.u-block-hover--tile::after,
.u-block-hover__additional--tile::before,
.u-block-hover__additional--tile::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 50%;
  transform: scale(0);
  opacity: 0;
}
.u-block-hover--tile::before,
.u-block-hover--tile::after {
  z-index: 1;
}
.u-block-hover--tile::before {
  top: 0;
  left: 0;
  transition-delay: 0s;
}
.u-block-hover--tile::after {
  top: 0;
  left: 50%;
  transition-delay: .105s;
}
.u-block-hover__additional--tile {
  z-index: 3;
}
.u-block-hover__additional--tile::before,
.u-block-hover__additional--tile::after {
  z-index: -1;
}
.u-block-hover__additional--tile::before {
  top: 50%;
  left: 0;
  transition-delay: .21s;
}
.u-block-hover__additional--tile::after {
  top: 50%;
  left: 50%;
  transition-delay: .35s;
}
.u-block-hover--tile:hover::before,
.u-block-hover--tile:hover::after,
.u-block-hover--tile:hover .u-block-hover__additional--tile::before,
.u-block-hover--tile:hover .u-block-hover__additional--tile::after {
  transform: scale(1);
  opacity: 1;
}
.u-block-hover__additional--tile__inner {
  opacity: 0;
}
.u-block-hover--tile:hover .u-block-hover__additional--tile__inner {
  opacity: 1;
  transition-delay: .35s;
}



//
// Cube
//

.u-block-hover--cube {
  overflow: visible;
  background-color: transparent;
  perspective: 50em;
  transform-style: preserve-3d;
}
[class*="u-block-hover__main--cube"] {
  transition-delay: .05s;
}
[class*="u-block-hover__additional--cube"] {
  opacity: 0;
  transition-delay: 0s;
}
.u-block-hover--cube:hover [class*="u-block-hover__main--cube"] {
  opacity: 0;
  transition-delay: 0s;
}
.u-block-hover--cube:hover [class*="u-block-hover__additional--cube"] {
  opacity: 1;
  transform: translateY(0%) rotateX(0deg);
  transition-delay: .05s;
}

// Cube-up
.u-block-hover__additional--cube-up {
  transform: translateY(50%) rotateX(-90deg);
}
.u-block-hover--cube:hover .u-block-hover__main--cube-up {
  transform: translateY(-50%) rotateX(90deg);
}

// Cube-down
.u-block-hover__additional--cube-down {
  transform: translateY(-50%) rotateX(90deg);
}
.u-block-hover--cube:hover .u-block-hover__main--cube-down {
  transform: translateY(50%) rotateX(-90deg);
}

// Cube-left
.u-block-hover__additional--cube-left {
  transform: translateX(-50%) rotateY(-90deg);
}
.u-block-hover--cube:hover .u-block-hover__main--cube-left {
  transform: translateX(50%) rotateY(90deg);
}

// Cube-right
.u-block-hover__additional--cube-right {
  transform: translateX(50%) rotateY(90deg);
}
.u-block-hover--cube:hover .u-block-hover__main--cube-right {
  transform: translateX(-50%) rotateY(-90deg);
}



//
// Border reveal
//

.u-block-hover--border-reveal::before,
.u-block-hover--border-reveal::after,
.u-block-hover__additional--border-reveal::before,
.u-block-hover__additional--border-reveal::after {
  content: "";
  position: absolute;
  background-color: $g-color-white;
  transform-origin: 0 0;
}
.u-block-hover--border-reveal::before,
.u-block-hover--border-reveal::after {
  left: 5px;
  right: 5px;
  height: 4px;
  z-index: 1;
  transform: scaleX(0);
}
.u-block-hover--border-reveal::before {
  top: 5px;
  transition-delay: .28s;
}
.u-block-hover--border-reveal:hover::before {
  transition-delay: 0s;
}
.u-block-hover--border-reveal::after {
  bottom: 5px;
  transition-delay: 0s;
}
.u-block-hover--border-reveal:hover::after {
  transition-delay: .28s;
}
.u-block-hover__additional--border-reveal {
  background-color: transparent;
  z-index: 3;
}
.u-block-hover__additional--border-reveal__inner {
  opacity: 0;
}
.u-block-hover__additional--border-reveal::before,
.u-block-hover__additional--border-reveal::after {
  top: 5px;
  bottom: 5px;
  width: 4px;
  z-index: -1;
  transform: scaleY(0);
}
.u-block-hover__additional--border-reveal::before {
  left: 5px;
  transition-delay: .28s;
}
.u-block-hover--border-reveal:hover .u-block-hover__additional--border-reveal::before {
  transition-delay: 0s;
}
.u-block-hover__additional--border-reveal::after {
  right: 5px;
  transition-delay: 0s;
}
.u-block-hover--border-reveal:hover .u-block-hover__additional--border-reveal::after {
  transition-delay: .28s;
}
.u-block-hover--border-reveal:hover .u-block-hover__main--border-reveal {
  opacity: 0;
}
.u-block-hover--border-reveal:hover::before,
.u-block-hover--border-reveal:hover::after,
.u-block-hover--border-reveal:hover .u-block-hover__additional--border-reveal::before,
.u-block-hover--border-reveal:hover .u-block-hover__additional--border-reveal::after {
  transform: scale(1);
}
.u-block-hover--border-reveal:hover .u-block-hover__additional--border-reveal__inner {
  opacity: 1;
  transition-delay: .35s;
}



//
// Mover
//

.u-block-hover:hover img[class*="u-block-hover__main--mover-"],
.u-block-hover:hover [class*="u-block-hover__additional--mover-"] {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}

img[class*="u-block-hover__main--mover-"] {
  max-width: initial;
  width: calc(100% + 60px);
  transition-duration: 0.5s;
  transform-origin: 50% 50%;
}
[class*="u-block-hover__additional--mover-"] {
  opacity: 0;
  transition-duration: 0.5s;
}

// Mover-left
img.u-block-hover__main--mover-left {
 transform: translate3d(-60px, 0, 0);
}
.u-block-hover__additional--mover-left {
  transform: translate3d(10px, 0, 0);
}

// Mover-right
img.u-block-hover__main--mover-right {
  transform: translate3d(-60px, 0, 0);
}
.u-block-hover__additional--mover-right {
  transform: translate3d(-10px, 0, 0);
}

// Mover-up
img.u-block-hover__main--mover-up {
  transform: translate3d(0, 20px, 0) scale3d(1.1, 1.1, 1.1);
}
.u-block-hover__additional--mover-up {
  transform: translate3d(0, 10px, 0);
}

// Mover-down
img.u-block-hover__main--mover-down {
  transform: translate3d(0, -20px, 0) scale3d(1.1, 1.1, 1.1);
}
.u-block-hover__additional--mover-down {
  transform: translate3d(0, -10px, 0);
}



//
// Focuser
//

.u-block-hover__additional--focuser-element {
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  opacity: .3;
  box-shadow: 0 0 0 31px rgba(0,0,0,.5);
  transform: scale3d(1.4, 1.4, 1.4);
  transition-duration: .5s;
}
.u-block-hover__additional--focuser-target {
  position: static;
  opacity: 0;
  transform: scale3d(1.4, 1.4, 1.4);
  transition-duration: .5s;
}
.u-block-hover:hover .u-block-hover__additional--focuser-target,
.u-block-hover:hover .u-block-hover__additional--focuser-element {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  transition-duration: .3s;
}



//
// Magnifier
//

img[class*="u-block-hover__main--magnifier"] {
  max-width: initial;
  width: calc(100% + 10px);
  margin: -10px 0;
}

[class*="u-block-hover__additional--magnifier-element"] {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  box-shadow: 0 0 0 4000px rgba(255,255,255,.3);
  z-index: 2;
  opacity: 0;
  transform: scale3d(.7, .7, .7);
  transform-origin: 50% 50%;
}
.u-block-hover:hover [class*="u-block-hover__additional--magnifier-element"] {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
[class*="u-block-hover__additional--magnifier-description"] {
  max-width: 115px;
  z-index: 3;
  opacity: 0;
}
.u-block-hover:hover [class*="u-block-hover__additional--magnifier-description"] {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

// Magnifier-bottom-right
.u-block-hover__additional--magnifier-element-bottom-right {
  top: auto;
  left: auto;
  bottom: -180px;
  right: -180px;
}
.u-block-hover__additional--magnifier-description-bottom-right {
  top: auto;
  right: 30px;
  bottom: 30px;
  left: auto;
  transform: translate3d(20px, 20px, 0);
}
.u-block-hover:hover img.u-block-hover__main--magnifier-bottom-right {
  transform: translate3d(-10px, -10px, 0);
}

// Magnifier-top-right
.u-block-hover__additional--magnifier-element-top-right {
  top: -180px;
  right: -180px;
  bottom: auto;
  left: auto;
}
.u-block-hover__additional--magnifier-description-top-right {
  top: 30px;
  right: 30px;
  bottom: auto;
  left: auto;
  transform: translate3d(20px, -20px, 0);
}
.u-block-hover:hover img.u-block-hover__main--magnifier-top-right {
  transform: translate3d(-10px, 10px, 0);
}

// Magnifier-bottom-left
img.u-block-hover__main--magnifier-bottom-left {
  transform: translate3d(-10px, 0, 0);
}
.u-block-hover__additional--magnifier-element-bottom-left {
  top: auto;
  left: -180px;
  bottom: -180px;
  right: auto;
}
.u-block-hover__additional--magnifier-description-bottom-left {
  top: auto;
  right: auto;
  bottom: 30px;
  left: 30px;
  transform: translate3d(-20px, 20px, 0);
}
.u-block-hover:hover img.u-block-hover__main--magnifier-bottom-left {
  transform: translate3d(0, -10px, 0);
}

// Magnifier-top-left
img.u-block-hover__main--magnifier-top-left {
  transform: translate3d(-10px, 0, 0);
}
.u-block-hover__additional--magnifier-element-top-left {
  top: -180px;
  left: -180px;
  bottom: auto;
  right: auto;
}
.u-block-hover__additional--magnifier-description-top-left {
  top: 30px;
  right: auto;
  bottom: auto;
  left: 30px;
  transform: translate3d(-20px, -20px, 0);
}
.u-block-hover:hover img.u-block-hover__main--magnifier-top-left {
  transform: translate3d(0, 10px, 0);
}



//
// Pappercuter
//

[class*="u-block-hover__additional--pappercuter"] {
  transform-origin: 50% 50%;
}
.u-block-hover__additional--pappercuter-inner {
  width: 100%;
  transform: rotate3d(0, 0, 1, 5deg);
}
.u-block-hover__additional--pappercuter-front,
.u-block-hover__additional--pappercuter-back {
  max-width: initial;
  width: 120%;
  top: -60px;
  bottom: -60px;
  left: -10%;
  z-index: 2;
  background-position: center;
  background-size: cover;
  transform: rotate3d(0, 0, 1, -5deg);
}
.u-block-hover__additional--pappercuter-front {
  clip: rect(0px, auto, 246px, 0px);
}
.u-block-hover__additional--pappercuter-back {
  top: -61px;
  clip: rect(246px, auto, auto, 0px);
}
.u-block-hover:hover .u-block-hover__additional--pappercuter-front {
  transform: scale3d(1.3, 1.3, 1.3) rotate3d(0, 0, 1, -10deg) translate3d(0, -45%, 0);
}
.u-block-hover:hover .u-block-hover__additional--pappercuter-back {
  transform: scale3d(1.3, 1.3, 1.3) rotate3d(0, 0, 1, -10deg) translate3d(0, 45%, 0);
}



//
// Slide Outside
//

[class*="u-block-hover__additional--outside"] {
  opacity: 0;
  visibility: hidden;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0.7, -1.2, 0.8, 1.2);
}
.u-block-hover:hover [class*="u-block-hover__additional--outside"] {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition-timing-function: cubic-bezier(0.25, 1.8, 0.8, 1);
}
.u-block-hover__additional--outside-down {
  transform: translate3d(0, -100%, 0);
}
.u-block-hover__additional--outside-up {
  transform: translate3d(0, 100%, 0);
}
.u-block-hover__additional--outside-left {
  transform: translate3d(100%, 0, 0);
}
.u-block-hover__additional--outside-right {
  transform: translate3d(-100%, 0, 0);
}



//
// Slide Lightspeed
//

[class*="u-block-hover__additional--lightspeed"] {
  visibility: hidden;
  opacity: 0;
  transition-duration: .7s;
  transition-timing-function: cubic-bezier(0.75, -1.2, 0.8, 2);
}
.u-block-hover:hover [class*="u-block-hover__additional--lightspeed"] {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0) skew(0deg, 0deg);
  transition-timing-function: cubic-bezier(0.25, 2, 0.75, 1);
}
.u-block-hover__additional--lightspeed-left {
  transform-origin: 50% 0%;
  transform: translate3d(150%, 0, 0) skew(-35deg, 0deg);
}
.u-block-hover__additional--lightspeed-right {
  transform-origin: 50% 100%;
  transform: translate3d(-150%, 0, 0) skew(35deg, 0deg);
}
.u-block-hover__additional--lightspeed-down {
  transform-origin: 50% 50%;
  transform: translate3d(0, -150%, 0) skew(0deg, -35deg);
}
.u-block-hover__additional--lightspeed-up {
  transform-origin: 100% 50%;
  transform: translate3d(0, 150%, 0) skew(0deg, -35deg);
}



//
// Rotate
//

[class*="u-block-hover__additional--rotate"] {
  opacity: 0;
  visibility: hidden;
}
.u-block-hover:hover [class*="u-block-hover__additional--rotate"] {
  opacity: 1;
  visibility: visible;
  transform: rotate3d(0, 0, 0, 0deg) scale3d(1, 1, 1);
}
.u-block-hover__additional--rotate-in {
  opacity: 1;
  transition-property: transform, opacity, visibility;
  transition-duration: .4s;
  transform: rotate3d(0, 0, 1, 720deg) scale3d(0, 0, 0);
}
.u-block-hover__additional--rotate-down-left {
  transform-origin: 0 100%;
  transform: rotate3d(0, 0, 1, -45deg);
}
.u-block-hover__additional--rotate-down-right {
  transform-origin: 100% 100%;
  transform: rotate3d(0, 0, 1, 45deg);
}
.u-block-hover__additional--rotate-up-left {
  transform-origin: 0 100%;
  transform: rotate3d(0, 0, 1, 45deg);
}
.u-block-hover__additional--rotate-up-right {
  transform-origin: 100% 100%;
  transform: rotate3d(0, 0, 1, -45deg);
}



//
// Jump
//

.u-block-hover__additional--jump {
  position: static;
}

.u-block-hover:hover .u-block-hover__additional--jump,
.u-block-hover.u-block-hover__additional--jump:hover  {
  transform: translate3d(0, -10px, 0);
}



/*------------------------------------
  Dedicated Properties
------------------------------------*/

.u-block-hover {
  &:hover,
  &:focus {

    /* Opacity */
    .u-block-hover__prop-opacity-1 {
      opacity: 1;
    }

    /* Colors */
    .u-block-hover__prop-color-white {
      color: #fff;
    }

    /* Background-colors */
    .u-block-hover__prop-bg-primary {
      background-color: $g-color-primary;

      &-opacity-0_9 {
        background-color: rgba($g-color-primary, .9);
      }
    }

  }
}