/*------------------------------------
  Carousel indicators v13
------------------------------------*/

.u-carousel-indicators-v13,
.u-carousel-indicators-v13--white {
  white-space: nowrap;

  li {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 5px;

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      text-indent: -9999px;
      transition: transform .3s ease, background-color .3s ease;

      &::before,
      &::after {
        content: "";
        display: none;
      }
    }

    &.slick-active {
      span {
        transform: scale(1.5);
      }
    }
  }
}

.u-carousel-indicators-v13--white {
  li {
    span {
      background: rgba($g-color-white, .5);

      &:hover,
      &:focus {
        background-color: $g-color-white;
      }
    }

    &.slick-active {
      span {
        background-color: $g-color-white;
      }
    }
  }
}
