/*------------------------------------
  Info Block v2-2
------------------------------------*/

.u-info-v2-2__item {
  position: relative;
  border-left: solid 1px;
  border-right: solid 1px;
  border-bottom: solid 1px;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 30%;
    border-top: solid 1px;
    z-index: 1;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }



  //
  // Border Colors
  //

  // White
  &.g-brd-white-dark-v3 {
    border-color: rgba($g-color-white, .3);

    &::before,
    &::after {
      border-color: rgba($g-color-white, .3);
    }
  }

  // Black
  &.g-brd-black-dark-v3 {
    border-color: rgba($g-color-black, .3);

    &::before,
    &::after {
      border-color: rgba($g-color-black, .3);
    }
  }
}