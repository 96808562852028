/*------------------------------------
  Checked state border
------------------------------------*/

/* Primary Colors */
.g-brd-primary {
  &--checked {
    input[type="checkbox"]:checked + &,
    input[type="radio"]:checked + &,
    input[type="checkbox"]:checked + * &,
    input[type="radio"]:checked + * & {
      border-color: $g-color-primary !important;
    }

    &.g-checked,
    .g-checked & {
      border-color: $g-color-primary !important;
    }
  }
}

/* Black Colors */
.g-brd-black {
  &--checked {
    input[type="checkbox"]:checked + &,
    input[type="radio"]:checked + &,
    input[type="checkbox"]:checked + * &,
    input[type="radio"]:checked + * & {
      border-color: $g-color-black !important;
    }

    &.g-checked,
    .g-checked & {
      border-color: $g-color-black !important;
    }
  }
}

/* White */
.g-brd-white {
  &--checked {
    input[type="checkbox"]:checked + &,
    input[type="radio"]:checked + &,
    input[type="checkbox"]:checked + * &,
    input[type="radio"]:checked + * & {
      border-color: $g-color-white !important;
    }

    &.g-checked,
    .g-checked & {
      border-color: $g-color-white !important;
    }
  }
}

/* Gray Colors */
.g-brd-gray {
  // Dark Gray
  &-dark {
    &-v1 {
      &--checked {
        input[type="checkbox"]:checked + &,
        input[type="radio"]:checked + &,
        input[type="checkbox"]:checked + * &,
        input[type="radio"]:checked + * & {
          border-color: $g-color-gray-dark-v1 !important;
        }

        &.g-checked,
        .g-checked & {
          border-color: $g-color-gray-dark-v1 !important;
        }
      }
    }

    &-v2 {
      &--checked {
        input[type="checkbox"]:checked + &,
        input[type="radio"]:checked + &,
        input[type="checkbox"]:checked + * &,
        input[type="radio"]:checked + * & {
          border-color: $g-color-gray-dark-v2 !important;
        }

        &.g-checked,
        .g-checked & {
          border-color: $g-color-gray-dark-v2 !important;
        }
      }
    }

    &-v3 {
      &--checked {
        input[type="checkbox"]:checked + &,
        input[type="radio"]:checked + &,
        input[type="checkbox"]:checked + * &,
        input[type="radio"]:checked + * & {
          border-color: $g-color-gray-dark-v3 !important;
        }

        &.g-checked,
        .g-checked & {
          border-color: $g-color-gray-dark-v3 !important;
        }
      }
    }

    &-v4 {
      &--checked {
        input[type="checkbox"]:checked + &,
        input[type="radio"]:checked + &,
        input[type="checkbox"]:checked + * &,
        input[type="radio"]:checked + * & {
          border-color: $g-color-gray-dark-v4 !important;
        }

        &.g-checked,
        .g-checked & {
          border-color: $g-color-gray-dark-v4 !important;
        }
      }
    }

    &-v5 {
      &--checked {
        input[type="checkbox"]:checked + &,
        input[type="radio"]:checked + &,
        input[type="checkbox"]:checked + * &,
        input[type="radio"]:checked + * & {
          border-color: $g-color-gray-dark-v5 !important;
        }

        &.g-checked,
        .g-checked & {
          border-color: $g-color-gray-dark-v5 !important;
        }
      }
    }
  }

  // Light Gray
  &-light {
    &-v1 {
      &--checked {
        input[type="checkbox"]:checked + &,
        input[type="radio"]:checked + &,
        input[type="checkbox"]:checked + * &,
        input[type="radio"]:checked + * & {
          border-color: $g-color-gray-light-v1 !important;
        }

        &.g-checked,
        .g-checked & {
          border-color: $g-color-gray-light-v1 !important;
        }
      }
    }

    &-v2 {
      &--checked {
        input[type="checkbox"]:checked + &,
        input[type="radio"]:checked + &,
        input[type="checkbox"]:checked + * &,
        input[type="radio"]:checked + * & {
          border-color: $g-color-gray-light-v2 !important;
        }

        &.g-checked,
        .g-checked & {
          border-color: $g-color-gray-light-v2 !important;
        }
      }
    }

    &-v3 {
      &--checked {
        input[type="checkbox"]:checked + &,
        input[type="radio"]:checked + &,
        input[type="checkbox"]:checked + * &,
        input[type="radio"]:checked + * & {
          border-color: $g-color-gray-light-v3 !important;
        }

        &.g-checked,
        .g-checked & {
          border-color: $g-color-gray-light-v3 !important;
        }
      }
    }

    &-v4 {
      &--checked {
        input[type="checkbox"]:checked + &,
        input[type="radio"]:checked + &,
        input[type="checkbox"]:checked + * &,
        input[type="radio"]:checked + * & {
          border-color: $g-color-gray-light-v4 !important;
        }

        &.g-checked,
        .g-checked & {
          border-color: $g-color-gray-light-v4 !important;
        }
      }
    }

    &-v5 {
      &--checked {
        input[type="checkbox"]:checked + &,
        input[type="radio"]:checked + &,
        input[type="checkbox"]:checked + * &,
        input[type="radio"]:checked + * & {
          border-color: $g-color-gray-light-v5 !important;
        }

        &.g-checked,
        .g-checked & {
          border-color: $g-color-gray-light-v5 !important;
        }
      }
    }
  }
}

/* Transparent */
.g-brd-transparent {
  &--checked {
    input[type="checkbox"]:checked + &,
    input[type="radio"]:checked + &,
    input[type="checkbox"]:checked + * &,
    input[type="radio"]:checked + * & {
      border-color: transparent !important;
    }

    &.g-checked,
    .g-checked & {
      border-color: transparent !important;
    }
  }
}

/* Color Red */
.g-brd-red {
  &--checked {
    input[type="checkbox"]:checked + &,
    input[type="radio"]:checked + &,
    input[type="checkbox"]:checked + * &,
    input[type="radio"]:checked + * & {
      border-color: $g-color-red !important;
    }

    &.g-checked,
    .g-checked & {
      border-color: $g-color-red !important;
    }
  }
}
