//
//  Bootstrap Styles
//  .text-nowrap, .text-justify, .text-truncate, .text-left, .text-right, .text-center
//  .text-lowercase, .text-uppercase, .text-capitalize,
//  .font-weight-normal, .font-weight-bold, .font-italic
//  .text-muted, .text-primary, .text-success, .text-info, .text-warning, .text-danger, .text-gray-dark
//

/* Text */
.g-word-break {
  word-wrap: break-word;
}

/* Puller */
.g-pull-50x {
  &-up {
    transform: translateY(-51%);
  }
  &-bottom {
    transform: translateY(51%);
  }
  &-left {
    transform: translateX(-51%);
  }
  &-right {
    transform: translateX(51%);
  }
}

/* Link overlay */
// .g-link-overlay {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   display: block;
//   z-index: 2;
// }