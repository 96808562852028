/*------------------------------------
  Clear
------------------------------------*/
.g-clear {
  clear: both;

  &--left {
    clear: left;
  }
  &--right {
    clear: right;
  }
}