/*------------------------------------
  Carousel indicators v17
------------------------------------*/

.u-carousel-indicators-v17,
.u-carousel-indicators-v17--white {
  white-space: nowrap;

  li {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 5px;

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      text-indent: -9999px;
      transition: box-shadow 0.3s ease;

      &::before,
      &::after {
        content: "";
        display: none;
      }
    }
  }
}

.u-carousel-indicators-v17--white {
  li {
    span {
      box-shadow: inset 0 0 0 8px rgba($g-color-white, .5);

      &:hover,
      &:focus {
        box-shadow: inset 0 0 0 8px $g-color-white;
      }
    }

    &.slick-active {
      span {
        box-shadow: inset 0 0 0 2px $g-color-white;
      }
    }
  }
}
