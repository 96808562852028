/*------------------------------------
  Opacity
------------------------------------*/
.opacity-0 {
  opacity: 0 !important;
}

.g-opacity-0_3 {
  opacity: .3 !important;
}

.g-opacity-0_4 {
  opacity: .4 !important;
}

.g-opacity-0_5 {
  opacity: .5 !important;
}

.g-opacity-0_6 {
  opacity: .6 !important;
}

.g-opacity-0_7 {
  opacity: .7 !important;
}

.g-opacity-0_8 {
  opacity: .8 !important;
}

.g-opacity-1 {
  opacity: 1 !important;
}

// Hover
.opacity-0 {
  &--hover:hover {
    opacity: 0 !important;
  }

  &--parent-hover {
    .g-parent:hover & {
      opacity: 0 !important;
    }
  }
}

.g-opacity-0_3 {
  &--hover:hover {
    opacity: .3 !important;
  }

  &--parent-hover {
    .g-parent:hover & {
      opacity: .3 !important;
    }
  }
}

.g-opacity-0_5 {
  &--hover:hover {
    opacity: .5 !important;
  }

  &--parent-hover {
    .g-parent:hover & {
      opacity: .5 !important;
    }
  }
}

.g-opacity-0_6 {
  &--hover:hover {
    opacity: .6 !important;
  }

  &--parent-hover {
    .g-parent:hover & {
      opacity: .6 !important;
    }
  }
}

.g-opacity-0_7 {
  &--hover:hover {
    opacity: .7 !important;
  }

  &--parent-hover {
    .g-parent:hover & {
      opacity: .7 !important;
    }
  }
}

.g-opacity-0_8 {
  &--hover:hover {
    opacity: .8 !important;
  }

  &--parent-hover {
    .g-parent:hover & {
      opacity: .8 !important;
    }
  }
}

.g-opacity-1 {
  .u-block-hover:hover &--hover,
  &--hover:hover {
    opacity: 1 !important;
  }

  &--parent-hover {
    .g-parent:hover & {
      opacity: 1 !important;
    }
  }

  &--parents-selected {
    .selected & {
      opacity: 1 !important;
    }
  }
}
