/*------------------------------------
  Badges
------------------------------------*/

[class*="u-badge"] {
  position: absolute;
  display: inline-block;
  text-align: center;
  @include px-to-rem("font-size", 13px);
  color: $g-color-main;
  z-index: 3;
}

[class*="u-badge"]:not([class*="--top-left"], [class*="--bottom-left"], [class*="--bottom-right"]) {
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.u-badge {
  &--top-left {
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
  }

  &--bottom-left {
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%);
  }

  &--bottom-right {
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
  }
}