// Add here all your customized mixins

@mixin breakpoint($point) {
  @if $point == tablet {
    @media (min-width: 768px) and (max-width: 1024px) {
      @content;
    }
  } @else if $point == phone {
    @media (max-width: 991px) {
      @content;
    }
  }@else if $point == 992up {
    @media (min-width: 1025px) {
      @content;
    }
  }
   @else if $point == desktop {
    @media (min-width: 1025px) {
      @content;
    }
  }
}