/*------------------------------------
  Heading-v2
------------------------------------*/
%u-heading-v2 {
  content: "";
  display: inline-block;
  border-top-style: solid;
  border-color: inherit;
}



//
// Imports
//

@import "heading-v2-1";
@import "heading-v2-2";
@import "heading-v2-3";
@import "heading-v2-4";
@import "heading-v2-5";
@import "heading-v2-6";
@import "heading-v2-7";
