/*------------------------------------
  Material: FAB
------------------------------------*/
.u-fixed-action-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 997;

  ul {
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    bottom: 60px;
    margin: 0;
    visibility: hidden;
    list-style-type: none;
    padding-left: 0;

    li {
      @include px-to-rem(margin-bottom, 15px);
    }

    a.u-btn-floating {
      opacity: 0;
    }
  }

  &.active {
    ul {
     visibility: visible;
    }
  }

  &-horizontal {
    @include px-to-rem(padding, 0 0 0 15px);

    ul {
      text-align: right;
      @include px-to-rem(right, 64px);
      top: 50%;
      transform: translateY(-50%);
      height: 100%;
      left: auto;
      width: 500px; /*width 100% only goes to width of button container */

      li {
        display: inline-block;
        @include px-to-rem(margin, 10px 15px 0 0);
      }
    }
  }

  &-toolbar {
    padding: 0;
    @include px-to-rem(height, 56px);

    &.active {
      & > a i {
        opacity: 0;
      }
    }

    ul {
      display: flex;
      top: 0;
      bottom: 0;
      z-index: 1;

      li {
        flex: 1;
        display: inline-block;
        margin: 0;
        height: 100%;
        transition: none;

        a {
          display: block;
          overflow: hidden;
          position: relative;
          width: 100%;
          height: 100%;
          background-color: transparent;
          box-shadow: none;
          color: #fff;
          @include px-to-rem(line-height, 56px);
          z-index: 1;

          i {
            line-height: inherit;
          }
        }
      }
    }
  }

  .u-fab-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    @include px-to-rem(width, 40px);
    @include px-to-rem(height, 40px);
    // background-color: $button-floating-background;
    border-radius: 50%;
    transform: scale(0);
  }
}