/*------------------------------------
  Heading-v5-3
------------------------------------*/
.u-heading-v5-3 {
  & .u-heading-v5__title::before {
    left: 2px;
    bottom: 0;
  }
  &.text-center .u-heading-v5__title::before {
    left: 50%;
    margin-left: -6px;
  }
  &.text-right .u-heading-v5__title::before {
    left: auto;
    right: 2px;
  }
  & .u-heading-v5__title {
    @include px-to-rem(padding-bottom, 25px);
  }
}