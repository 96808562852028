/* Button Primary */
.u-btn-primary {
  @include u-button-flat ($g-color-primary, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-primary);
  @include u-button-flat-hover (1, 2, $g-color-primary);
  @include u-button-flat-hover (1, 3, $g-color-primary);
  @include u-button-flat-hover (1, 4, $g-color-primary);
  @include u-button-flat-hover (2, 1, $g-color-primary);
  @include u-button-flat-hover (2, 2, $g-color-primary);
}

/* Button White */
.u-btn-white {
  @include u-button-flat ($g-color-white, $g-color-gray-dark-v3, $g-color-gray-dark-v3);
  @include u-button-flat-hover (1, 1, $g-color-white);
  @include u-button-flat-hover (1, 2, $g-color-white);
  @include u-button-flat-hover (1, 3, $g-color-white);
  @include u-button-flat-hover (1, 4, $g-color-white);
  @include u-button-outline-hover (2, 1, $g-color-white);
  @include u-button-outline-hover (2, 2, $g-color-white);
}

/* Button Black */
.u-btn-black {
  @include u-button-flat ($g-color-black, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-black);
  @include u-button-flat-hover (1, 2, $g-color-black);
  @include u-button-flat-hover (1, 3, $g-color-black);
  @include u-button-flat-hover (1, 4, $g-color-black);
  @include u-button-outline-hover (2, 1, $g-color-black);
  @include u-button-outline-hover (2, 2, $g-color-black);
}

/* Button Dark Gray */
.u-btn-darkgray {
  @include u-button-flat ($g-color-gray-dark-v2, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-gray-dark-v2);
  @include u-button-flat-hover (1, 2, $g-color-gray-dark-v2);
  @include u-button-flat-hover (1, 3, $g-color-gray-dark-v2);
  @include u-button-flat-hover (1, 4, $g-color-gray-dark-v2);
  @include u-button-flat-hover (2, 1, $g-color-gray-dark-v2);
  @include u-button-flat-hover (2, 2, $g-color-gray-dark-v2);
}

/* Button Red */
.u-btn-red {
  @include u-button-flat ($g-color-red, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-red);
  @include u-button-flat-hover (1, 2, $g-color-red);
  @include u-button-flat-hover (1, 3, $g-color-red);
  @include u-button-flat-hover (1, 4, $g-color-red);
  @include u-button-flat-hover (2, 1, $g-color-red);
  @include u-button-flat-hover (2, 2, $g-color-red);
}

/* Button Red Tomato */
.u-btn-lightred {
  @include u-button-flat ($g-color-lightred, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-lightred);
  @include u-button-flat-hover (1, 2, $g-color-lightred);
  @include u-button-flat-hover (1, 3, $g-color-lightred);
  @include u-button-flat-hover (1, 4, $g-color-lightred);
  @include u-button-flat-hover (2, 1, $g-color-lightred);
  @include u-button-flat-hover (2, 2, $g-color-lightred);
}

/* Button Dark Red */
.u-btn-darkred {
  @include u-button-flat ($g-color-darkred, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-darkred);
  @include u-button-flat-hover (1, 2, $g-color-darkred);
  @include u-button-flat-hover (1, 3, $g-color-darkred);
  @include u-button-flat-hover (1, 4, $g-color-darkred);
  @include u-button-flat-hover (2, 1, $g-color-darkred);
  @include u-button-flat-hover (2, 2, $g-color-darkred);
}

/* Button Blue */
.u-btn-blue {
  @include u-button-flat ($g-color-blue, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-blue);
  @include u-button-flat-hover (1, 2, $g-color-blue);
  @include u-button-flat-hover (1, 3, $g-color-blue);
  @include u-button-flat-hover (1, 4, $g-color-blue);
  @include u-button-flat-hover (2, 1, $g-color-blue);
  @include u-button-flat-hover (2, 2, $g-color-blue);
}

/* Button Indigo */
.u-btn-indigo {
  @include u-button-flat ($g-color-indigo, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-indigo);
  @include u-button-flat-hover (1, 2, $g-color-indigo);
  @include u-button-flat-hover (1, 3, $g-color-indigo);
  @include u-button-flat-hover (1, 4, $g-color-indigo);
  @include u-button-flat-hover (2, 1, $g-color-indigo);
  @include u-button-flat-hover (2, 2, $g-color-indigo);
}

/* Button Purple */
.u-btn-purple {
  @include u-button-flat ($g-color-purple, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-purple);
  @include u-button-flat-hover (1, 2, $g-color-purple);
  @include u-button-flat-hover (1, 3, $g-color-purple);
  @include u-button-flat-hover (1, 4, $g-color-purple);
  @include u-button-flat-hover (2, 1, $g-color-purple);
  @include u-button-flat-hover (2, 2, $g-color-purple);
}

/* Button Dark Purple */
.u-btn-darkpurple {
  @include u-button-flat ($g-color-darkpurple, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-darkpurple);
  @include u-button-flat-hover (1, 2, $g-color-darkpurple);
  @include u-button-flat-hover (1, 3, $g-color-darkpurple);
  @include u-button-flat-hover (1, 4, $g-color-darkpurple);
  @include u-button-flat-hover (2, 1, $g-color-darkpurple);
  @include u-button-flat-hover (2, 2, $g-color-darkpurple);
}

/* Button Pink */
.u-btn-pink {
  @include u-button-flat ($g-color-pink, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-pink);
  @include u-button-flat-hover (1, 2, $g-color-pink);
  @include u-button-flat-hover (1, 3, $g-color-pink);
  @include u-button-flat-hover (1, 4, $g-color-pink);
  @include u-button-flat-hover (2, 1, $g-color-pink);
  @include u-button-flat-hover (2, 2, $g-color-pink);
}

/* Button Orange */
.u-btn-orange {
  @include u-button-flat ($g-color-orange, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-orange);
  @include u-button-flat-hover (1, 2, $g-color-orange);
  @include u-button-flat-hover (1, 3, $g-color-orange);
  @include u-button-flat-hover (1, 4, $g-color-orange);
  @include u-button-flat-hover (2, 1, $g-color-orange);
  @include u-button-flat-hover (2, 2, $g-color-orange);
}

/* Button Deep Orange */
.u-btn-deeporange {
  @include u-button-flat ($g-color-deeporange, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-deeporange);
  @include u-button-flat-hover (1, 2, $g-color-deeporange);
  @include u-button-flat-hover (1, 3, $g-color-deeporange);
  @include u-button-flat-hover (1, 4, $g-color-deeporange);
  @include u-button-flat-hover (2, 1, $g-color-deeporange);
  @include u-button-flat-hover (2, 2, $g-color-deeporange);
}

/* Button Yellow */
.u-btn-yellow {
  @include u-button-flat ($g-color-yellow, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-yellow);
  @include u-button-flat-hover (1, 2, $g-color-yellow);
  @include u-button-flat-hover (1, 3, $g-color-yellow);
  @include u-button-flat-hover (1, 4, $g-color-yellow);
  @include u-button-flat-hover (2, 1, $g-color-yellow);
  @include u-button-flat-hover (2, 2, $g-color-yellow);
}

/* Button Aqua */
.u-btn-aqua {
  @include u-button-flat ($g-color-aqua, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-aqua);
  @include u-button-flat-hover (1, 2, $g-color-aqua);
  @include u-button-flat-hover (1, 3, $g-color-aqua);
  @include u-button-flat-hover (1, 4, $g-color-aqua);
  @include u-button-flat-hover (2, 1, $g-color-aqua);
  @include u-button-flat-hover (2, 2, $g-color-aqua);
}

/* Button Cyan */
.u-btn-cyan {
  @include u-button-flat ($g-color-cyan, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-cyan);
  @include u-button-flat-hover (1, 2, $g-color-cyan);
  @include u-button-flat-hover (1, 3, $g-color-cyan);
  @include u-button-flat-hover (1, 4, $g-color-cyan);
  @include u-button-flat-hover (2, 1, $g-color-cyan);
  @include u-button-flat-hover (2, 2, $g-color-cyan);
}

/* Button Teal */
.u-btn-teal {
  @include u-button-flat ($g-color-teal, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-teal);
  @include u-button-flat-hover (1, 2, $g-color-teal);
  @include u-button-flat-hover (1, 3, $g-color-teal);
  @include u-button-flat-hover (1, 4, $g-color-teal);
  @include u-button-flat-hover (2, 1, $g-color-teal);
  @include u-button-flat-hover (2, 2, $g-color-teal);
}

/* Button Brown */
.u-btn-brown {
  @include u-button-flat ($g-color-brown, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-brown);
  @include u-button-flat-hover (1, 2, $g-color-brown);
  @include u-button-flat-hover (1, 3, $g-color-brown);
  @include u-button-flat-hover (1, 4, $g-color-brown);
  @include u-button-flat-hover (2, 1, $g-color-brown);
  @include u-button-flat-hover (2, 2, $g-color-brown);
}

/* Button Bluegrey */
.u-btn-bluegray {
  @include u-button-flat ($g-color-bluegray, $g-color-white, $g-color-white);
  @include u-button-flat-hover (1, 1, $g-color-bluegray);
  @include u-button-flat-hover (1, 2, $g-color-bluegray);
  @include u-button-flat-hover (1, 3, $g-color-bluegray);
  @include u-button-flat-hover (1, 4, $g-color-bluegray);
  @include u-button-flat-hover (2, 1, $g-color-bluegray);
  @include u-button-flat-hover (2, 2, $g-color-bluegray);
}

/* Button Facebook */
.u-btn-facebook {
  @include u-button-flat ($g-color-facebook, $g-color-white, $g-color-white);
}

/* Button Twitter */
.u-btn-twitter {
  @include u-button-flat ($g-color-twitter, $g-color-white, $g-color-white);
}

/* Button Instagram */
.u-btn-instagram {
  @include u-button-flat ($g-color-instagram, $g-color-white, $g-color-white);
}

/* Button VK */
.u-btn-vk {
  @include u-button-flat ($g-color-vk, $g-color-white, $g-color-white);
}

/* Button Google Plus */
.u-btn-google-plus {
  @include u-button-flat ($g-color-google-plus, $g-color-white, $g-color-white);
}