/*------------------------------------
  Widths
------------------------------------*/
//
// .w-25 .w-50 .w-75 .w-100
// .mw-100 {
//   max-width: 100% !important;
// }
//

/* Width in Percentage (%) */
$i: 30;
@while $i < 91 {
  .g-width-#{$i}x {
    width: $i*1% !important; /* P */
  }
  $i: $i + 10;
}

@media (max-width: #{$g-2xs}-1) {
  .w-100--2xs {
    width: 100% !important;
  }
}

@media (min-width: $g-sm) {
  @while $i < 91 {
    .g-width-#{$i}x--sm {
      width: $i*1% !important; /* P */
    }
    $i: $i + 10;
  }
  .g-width-20x--sm {
    width: 20% !important;
  }
  .g-width-25x--sm {
    width: 25% !important;
  }
  .g-width-33_3x--sm {
    width: 33.333333% !important;
  }
  .g-width-40x--sm {
    width: 40% !important;
  }
  .g-width-60x--sm {
    width: 60% !important;
  }
  .g-width-16_6x--sm {
    width: 16.666666% !important;
  }
  .g-width-66_6x--sm {
    width: 66.666666% !important;
  }
}

@media (min-width: $g-md) {
  $i: 20; /* P */
  @while $i < 91 {
    .g-width-#{$i}x--md {
      width: $i*1% !important; /* P */
    }
    $i: $i + 5;
  }
  .g-width-33_3x--md { /* P */
    width: 33.333333% !important;
  }
  .g-width-16_6x--md { /* P */
    width: 16.666666% !important;
  }
  .g-width-66_6x--md { /* P */
    width: 66.666666% !important;
  }
}

@media (min-width: $g-lg) {
  @while $i < 91 {
    .g-width-#{$i}x--lg {
      width: $i*1% !important; /* P */
    }
    $i: $i + 10;
  }
}

@media (min-width: $g-xl) {
  @while $i < 91 {
    .g-width-#{$i}x--xl {
      width: $i*1% !important; /* P */
    }
    $i: $i + 10;
  }
}

.g-width-auto {
  width: auto !important; /* P */
}

/* Max Width in Percentage (%) */
.g-width-1x {
  max-width: 1%;
}

.g-width-80x {
  max-width: 80%;
}

.g-width-90x {
  max-width: 90%;
}

.g-width-95x {
  width: 95%;
}

.g-max-width-60x {
  max-width: 60%;
}

.g-max-width-100x {
  max-width: 100%;
}

/* Min Width in Percentage (%) */
.g-min-width-100x {
  min-width: 100%;
}

/* Width Viewport Width (vw) */
.g-width-50vw {
  width: 50vw !important; /* P */
}

.g-width-100vw {
  width: 100vw !important; /* P */
}

/* Width in Pixels (px) */
.g-width-3 {
  width: 3px !important; /* O */
}

.g-width-10 {
  width: 10px !important; /* O */
}

.g-width-12 {
  width: 12px !important; /* Z */
}

.g-width-16 {
  width: 16px !important; /* O */
}

.g-width-18 {
  width: 18px !important; /* O */
}

.g-width-20 {
  width: 20px !important; /* O */
}

.g-width-24 {
  width: 24px !important; /* O */
}

.g-width-25 {
  width: 25px !important; // Z
}

.g-width-26 {
  width: 26px !important; /* P */
}

.g-width-28 {
  width: 28px !important; /* O */
}

.g-width-30 {
  width: 30px !important; /* P */
}

.g-width-32 {
  width: 32px !important; /* O */
}

.g-width-35 {
  width: 35px !important; /* P */
}

.g-width-36 {
  width: 36px !important; /* O */
}

.g-width-40 {
  width: 40px !important; /* P */
}

.g-width-45 {
  width: 45px !important; /* P */
}

.g-width-48 {
  width: 48px !important; /* P */
}

.g-width-50 {
  width: 50px !important; /* P */
}

.g-width-54 {
  width: 54px !important; /* P */
}

.g-width-55 {
  width: 55px !important; /* P */
}

.g-width-60 {
  width: 60px !important; /* P */
}

.g-width-64 {
  width: 64px !important; /* P */
}

.g-width-70 {
  width: 70px !important; /* P */
}

.g-width-75 {
  width: 75px !important; /* O */
}

.g-width-80 {
  width: 80px !important; /* P */
}

.g-width-85 {
  width: 85px !important; /* P */
}

.g-width-95 {
  width: 95px !important; /* P */
}

.g-width-100 {
  width: 100px !important; /* P */
}

.g-width-105 {
  width: 105px;
}

.g-width-110 {
  width: 110px !important; /* O */
}

.g-width-115 {
  width: 115px !important; /* O */
}

.g-width-120 {
  width: 120px !important; /* P */
}

.g-width-125 {
  width: 125px !important; /* P */
}

.g-width-130 {
  width: 130px !important; /* P */
}

.g-width-135 {
  width: 135px !important; /* O */
}

.g-width-140 {
  width: 140px !important; /* P */
}

.g-width-150 {
  width: 150px !important;
}

.g-width-160 {
  width: 160px !important; /* P */
}

.g-width-170 {
  width: 170px !important; /* P */
}

.g-width-180 {
  width: 180px !important; /* O */
}

.g-width-200 {
  width: 200px !important; /* P */
}

.g-width-220 {
  width: 220px !important;
}

.g-width-215 {
  width: 215px !important;
}

.g-width-235 {
  width: 235px !important;
}

.g-width-250 { /* RG-Q */
  width: 250px !important; /* P */
  max-width: 100%;
}

.g-width-270 {
  width: 270px !important; /* O */
  max-width: 100%;
}

.g-width-300 {
  width: 300px !important; /* P */
  max-width: 100%;
}

.g-width-400 {
  width: 400px !important; /* O */
  max-width: 100%;
}

.g-width-340 {
  width: 340px !important; /* O */
  max-width: 100%;
}

.g-width-360 {
  width: 360px !important; /* O */
  max-width: 100%;
}

.g-width-370 {
  width: 370px !important; /* P */
  max-width: 100%;
}

.g-width-380 {
  width: 380px !important; /* O */
  max-width: 100%;
}

.g-width-410 {
  width: 410px !important; /* O */
}

.g-width-460 {
  width: 460px !important; /* O */
  max-width: 100%;
}

.g-width-465 {
  width: 465px !important; /* O */
  max-width: 100%;
}

.g-width-485 {
  width: 485px !important; /* O */
  max-width: 100%;
}

.g-width-560 {
  width: 560px !important; /* O */
  max-width: 100%;
}

.g-width-590 {
  width: 590px !important;
  max-width: 100%;
}

.g-width-600 {
  width: 600px !important; /* O */
  max-width: 100%;
}

.g-width-630 {
  width: 630px !important; /* O */
  max-width: 100%;
}

.g-width-680 {
  width: 680px !important; /* O */
  max-width: 100%;
}

.g-width-720 {
  width: 720px !important; /* O */
  max-width: 100%;
}

.g-width-760 {
  width: 760px !important;
  max-width: 100%;
}

.g-width-770 {
  width: 770px !important;
  max-width: 100%;
}

.g-width-780 { /* RG-Q */
  width: 780px !important; /* P */
  max-width: 100%;
}

.g-width-900 {
  width: 900px !important; /* P */
  max-width: 100%;
}

.g-width-945 {
  width: 945px !important;
  max-width: 100%;
}

.g-width-1025 {
  width: 1025px !important;
  max-width: 100%;
}

/* Max Width in Pixels (px) */
.g-max-width-100 {
  max-width: 100px;
}

.g-max-width-120 {
  max-width: 120px;
}

.g-max-width-170 {
  max-width: 170px; /* O */
}

.g-max-width-190 { // Z
  max-width: 190px;
}

.g-max-width-200 {
  max-width: 200px;
}

.g-max-width-250 {
  max-width: 250px;
}

.g-max-width-300 {
  max-width: 300px;
}

.g-max-width-400 {
  max-width: 400px;
}

.g-max-width-500 {
  max-width: 500px;
}

.g-max-width-540 {
  max-width: 540px;
}

.g-max-width-550 {
  max-width: 550px;
}

.g-max-width-570 {
  max-width: 570px;
}

.g-max-width-600 {
  max-width: 600px;
}

.g-max-width-645 {
  max-width: 645px;
}

.g-max-width-670 {
  max-width: 670px;
}

.g-max-width-700 {
  max-width: 700px;
}

.g-max-width-750 {
  max-width: 750px;
}

.g-max-width-770 {
  max-width: 770px;
}

.g-max-width-780 {
  max-width: 780px;
}

.g-max-width-800 {
  max-width: 800px;
}

.g-max-width-840 {
  max-width: 840px;
}

.g-max-width-870 {
  max-width: 870px;
}

.g-max-width-960 {
  max-width: 960px;
}

/* Min Width in Pixels (px) */
.g-min-width-35 {
  min-width: 35px;
}

.g-min-width-40 {
  min-width: 40px;
}

.g-min-width-65 {
  min-width: 65px;
}

.g-min-width-110 {
  min-width: 110px;
}

.g-min-width-130 {
  min-width: 130px;
}

.g-min-width-150 {
  min-width: 150px;
}

.g-min-width-170 {
  min-width: 170px;
}

.g-min-width-200 {
  min-width: 200px;
}

.g-min-width-220 {
  min-width: 220px;
}

.g-min-width-300 {
  min-width: 300px;
}

.g-min-width-400 {
  min-width: 400px;
}

@media (min-width: $g-sm) {
  .g-width-50x--sm {
    width: 50% !important; /* P */
  }
  .g-width-80x--sm {
    width: 80% !important; /* R */
  }
  .g-width-100--sm {
    width: 100px !important; /* P */
  }
  .g-width-150--sm {
    width: 150px !important; /* O */
  }
  .g-width-170--sm {
    width: 170px !important; /* O */
  }
  .g-width-180--sm {
    width: 180px !important;
  }
  .g-min-width-180--sm {
    min-width: 180px;
  }
  .g-width-485--sm {
    width: 485px !important; /* O */
    max-width: 100%;
  }

  .g-width-auto--sm {
    width: auto !important;
  }

  .g-max-width-300--sm {
    max-width: 300px;
  }
}

@media (min-width: $g-md) {
  .g-width-40--md {
    width: 40px !important; /* O */
  }
  .g-width-45--md {
    width: 45px !important; /* O */
  }
  .g-width-60--md {
    width: 60px !important; /* O */
  }
  .g-width-80--md {
    width: 80px !important; /* O */
  }
  .g-width-50x--md {
    width: 50% !important; /* P */
  }
  .g-width-60x--md {
    width: 60% !important; /* P */
  }
  .g-width-80x--md {
    width: 80% !important; /* R */
  }
  .g-width-45--md {
    width: 45px !important;
  }
  .g-width-100--md {
    width: 100px !important; /* O */
  }
  .g-width-125--md {
    width: 125px !important; /* P */
  }
  .g-width-135--md {
    width: 135px !important;
  }
  .g-width-140--md {
    width: 140px !important; /* R */
  }
  .g-width-155--md {
    width: 155px !important; /* R */
  }
  .g-width-180--md {
    width: 180px !important;
  }
  .g-width-225--md {
    width: 225px !important;
  }
  .g-width-160--md {
    width: 160px !important;
  }
  .g-width-165--md {
    width: 165px !important;
  }
  .g-width-170--md {
    width: 170px !important; /* P */
  }
  .g-width-185--md {
    width: 185px !important; /* O */
  }
  .g-width-280--md {
    width: 280px !important; /* O */
  }
  .g-width-780--md {
    width: 780px !important; /* O */
    max-width: 100%;
  }
  .g-max-width-200--md {
    max-width: 200px;
  }
  .g-max-width-400--md {
    max-width: 400px;
  }
  .g-min-width-110--md {
    min-width: 110px;
  }
  .g-width-auto--md {
    width: auto !important; /* P */
  }
}

@media (min-width: $g-lg) {
  .g-width-60x--lg {
    width: 60% !important; /* J */
  }
  .g-width-100x--lg {
    width: 100% !important; /* P */
  }
  .g-width-155--lg {
    width: 155px !important; /* O */
  }
  .g-width-165--lg {
    width: 165px !important;
  }
  .g-width-200--lg {
    width: 200px !important; /* O */
    max-width: 100%;
  }
  .g-width-250--lg { /* RG-Q */
    width: 250px !important; /* P */
    max-width: 100%;
  }
  .g-width-485--lg {
    width: 485px !important; /* O */
    max-width: 100%;
  }
  .g-width-auto--lg {
    width: auto !important; /* P */
  }

  .g-max-width-200--lg {
    max-width: 200px;
  }
}

@media (min-width: $g-xl) {
  .g-min-width-315--xl {
    min-width: 315px;
  }
}

// Test from Oleg
* {
  > .g-width-70--hover {
    transition: {
      property: width;
      duration: .2s;
      timing-function: ease-in;
    }
  }

  &:hover {
    > .g-width-70--hover {
      width: 70px;
    }
  }
}
