/*------------------------------------
  Heading-v4
------------------------------------*/
[class*="u-heading-v4-"] {
  @include px-to-rem(padding, 2px 0 3px 20px);
  border-left-width: 4px;
  border-left-style: solid;
  border-color: inherit;

  &.text-right {
    @include px-to-rem(padding, 2px 20px 3px 0);
    border-left-width: 0;
    border-right-width: 4px;
    border-right-style: solid;
  }
}