/*------------------------------------
  List Style Types
------------------------------------*/
.g-list-style {
  &-circle {
    list-style-type: circle;
  }
  &-disc {
    list-style-type: disc;
  }
  &-square {
    list-style-type: square;
  }
  &-lower-roman {
    list-style-type: lower-roman;
  }
  &-upper-roman {
    list-style-type: upper-roman;
  }
  &-lower-latin {
    list-style-type: lower-latin;
  }
  &-upper-latin {
    list-style-type: upper-latin;
  }
}
