/*------------------------------------
  Focus state color
------------------------------------*/

/* Primary Colors */
.g-color-primary {
  &:focus {
    color: $g-color-primary;
  }
}

/* Black Colors */
.g-color-black {
  &--focus:focus {
    color: $g-color-black;
  }
}

/* White Colors */
.g-color-white {
  &--focus:focus {
    color: $g-color-white !important;
  }
}

/* Gray Colors */
.g-color-gray {
  // Gray Light
  &-light {
    &-v4 {
      &:focus {
        color: $g-color-gray-light-v4 !important;
      }
    }
  }
}
