//
// Extend for some hover effects
//
%u-icon-hover-effect-after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  box-sizing: content-box;
  pointer-events: none;
}