/*------------------------------------
  jQuery UI
------------------------------------*/

.ui {
  // Autocomplete
  &-autocomplete {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 1px;
    z-index: 991;

    .left {
      float: left;
    }

    .right {
      float: right;
    }

    .ui-menu-item {
      padding: 0;

      &-wrapper {
        display: block;
        color: inherit;
        background-color: transparent;
        border-color: transparent;
        padding: 7px 15px;

        &.ui-state-active,
        &:hover {
          color: $g-color-white;
          background-color: $g-color-primary;
          text-decoration: none;
        }
      }

      &:hover {
        color: $g-color-white;
        background-color: $g-color-primary;

        .ui-menu-item {
          &-wrapper {
            background-color: transparent;
            border-color: transparent;
          }
        }
      }
    }

    &-category {
      padding: 5px 15px;
      margin: 0;
      font-weight: bold;
    }
  }
}