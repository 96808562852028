/*------------------------------------
  Text-shadow
------------------------------------*/

// Text shadow v1
.u-txt-shadow-v1 {
  text-shadow: 1px 1px rgba(67,70,72,.05),
               2px 2px rgba(147,150,152,.07),
               3px 3px rgba(178,182, 183,.086),
               4px 4px rgba(195,199,200,.106),
               5px 5px rgba(206,210,211,.125),
               6px 6px rgba(213,217,218,.145),
               7px 7px rgba(218,222,223,.165),
               8px 8px rgba(222,226,227,.184),
               9px 9px rgba(226,230,231,.204),
               10px 10px rgba(228,232,233,.22),
               11px 11px rgba(230,234,235,.24),
               12px 12px rgba(232,236,237,.26),
               13px 13px rgba(233,237,238,.28),
               14px 14px rgba(235,239,240,.298),
               15px 15px rgba(236,240,241,.318),
               16px 16px rgba(237,241,242,.333),
               17px 17px rgba(238,242,243,.353),
               18px 18px rgba(238,242,243,.373),
               19px 19px rgba(239,243,244,.392),
               20px 20px rgba(240,244,245,.41),
               21px 21px rgba(240,244,245,.43),
               22px 22px rgba(241,245,246,.447),
               23px 23px rgba(241,245,246,.467),
               24px 24px rgba(242,246,247,.486),
               25px 25px rgba(242,246,247,.506),
               26px 26px rgba(242,246,247,.525),
               27px 27px rgba(243,247,248,.545),
               28px 28px rgba(243,247,248,.565),
               29px 29px rgba(243,247,248,.58),
               30px 30px rgba(244,248,249,.6),
               31px 31px rgba(244,248,249,.62),
               32px 32px rgba(244,248,249,.64),
               33px 33px rgba(244,248,249,.66),
               34px 34px rgba(245,249,250,.68),
               35px 35px rgba(245,249,250,.694),
               36px 36px rgba(245,249,250,.714),
               37px 37px rgba(245,249,250,.733),
               38px 38px rgba(245,249,250,.753),
               39px 39px rgba(246,250,251,.773),
               40px 40px rgba(246,250,251,.792),
               41px 41px rgba(246,250,251,.81),
               42px 42px rgba(246,250,251,.827),
               43px 43px rgba(246,250,251,.847),
               44px 44px rgba(246,250,251,.867),
               45px 45px rgba(246,250,251,.886),
               46px 46px rgba(246,250,251,.906),
               47px 47px rgba(247,251,252,.925),
               48px 48px rgba(247,251,252,.94),
               49px 49px rgba(247,251,252,.96),
               50px 50px rgba(247,251,252,.98)
}

// Text shadow v2
.u-txt-shadow-v2 {
  text-shadow: 0 6px 55px $g-color-gray-dark-v5;
}