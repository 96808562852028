/*------------------------------------
  Checked state overlay
------------------------------------*/

.g-overlay {
  &-black {
    &-0_5 {
      &--checked {
        input[type="checkbox"]:checked + &,
        input[type="radio"]:checked + &,
        input[type="checkbox"]:checked + * > &,
        input[type="radio"]:checked + * > & {
          &::after {
            background-color: rgba($g-color-black, .5);
          }
        }
      }
    }

    &-0_7 {
      &--checked {
        input[type="checkbox"]:checked + &,
        input[type="radio"]:checked + &,
        input[type="checkbox"]:checked + * > &,
        input[type="radio"]:checked + * > & {
          &::after {
            background-color: rgba($g-color-black, .7);
          }
        }
      }
    }

    &-gradient {
      &-v1 {
        &--checked {
          input[type="checkbox"]:checked + &,
          input[type="radio"]:checked + &,
          input[type="checkbox"]:checked + * > &,
          input[type="radio"]:checked + * > & {
            &::after {
              background-image: linear-gradient(to bottom, transparent 40%, $g-color-black 100%);
            }
          }
        }
      }
    }
  }

  &-primary {
    &-0_5 {
      &--checked {
        input[type="checkbox"]:checked + &,
        input[type="radio"]:checked + &,
        input[type="checkbox"]:checked + * &,
        input[type="radio"]:checked + * & {
          &::after {
            background-color: rgba($g-color-primary, .5);
          }
        }

        &.g-checked,
        .g-checked & {
          &::after {
            background-color: rgba($g-color-primary, .5);
          }
        }
      }
    }

    &-0_9 {
      &--checked {
        input[type="checkbox"]:checked + &,
        input[type="radio"]:checked + &,
        input[type="checkbox"]:checked + * &,
        input[type="radio"]:checked + * & {
          &::after {
            background-color: rgba($g-color-primary, .9);
          }
        }

        &.g-checked,
        .g-checked & {
          &::after {
            background-color: rgba($g-color-primary, .9);
          }
        }
      }
    }
  }
}
