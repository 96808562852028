/*------------------------------------
  Typography
------------------------------------*/

// main {
//   overflow-x: hidden;
// }

.popovers--no-title .popover-title {
  display: none;
}

.g-nowrap {
  white-space: nowrap;
}