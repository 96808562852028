/*------------------------------------
  Tabs v6
------------------------------------*/
@media (min-width: $g-md) {
  [class*="u-nav-v6"] {
    & .nav-link {
      position: relative;
      border-bottom: {
        style: solid;
        width: 2px;
        color: transparent;
      }
      transition: none;

      &:after,
      &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      &:after {
        border-top-color: $g-bg-color-main;
        border-width: 4px;
        margin-left: -4px;
      }
      &:before {
        border-width: 6px;
        margin-left: -6px;
      }
    }

    & .nav-link.active {
      border-color: $g-color-primary;

      &::after,
      &::before {
        opacity: 1;
      }

      &::before {
        border-top-color: $g-color-primary;
      }
    }

    &.u-nav-light {
      & .nav-link {
        color: $g-color-white;

        &::after {
          border-top-color: $g-color-gray-dark-v2;
        }
      }

      & .nav-link.active {
        color: $g-color-white;
        border-color: $g-color-white;

        &:before {
          border-top-color: $g-color-white;
        }

        &::after {
          border-top-color: $g-color-gray-dark-v2;
        }
      }
    }

    &.flex-column {
      & .nav-link {
        border-bottom: none;
        border-right: {
          style: solid;
          width: 2px;
          color: transparent;
        }

        &:after,
        &:before {
          top: 50%;
          left: auto;
        }
        &:after {
          border-top-color: transparent;
          border-left-color: $g-bg-color-main;
          border-width: 4px;
          margin-left: 0;
          margin-top: -5px;
          right: -8px;
        }
        &:before {
          border-top-color: transparent;
          border-left-color: $g-color-white;
          border-width: 6px;
          margin-left: 0;
          margin-top: -7px;
          right: -13px;
        }
      }

      & .nav-link.active {
        border-color: $g-color-primary;

        &::before {
          border-left-color: $g-color-primary;
        }
      }
    }
  }
}
