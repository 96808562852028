/*------------------------------------
  Carousel indicators v3
------------------------------------*/

.u-carousel-indicators-v3 {
  li {
    margin: 0 3px;
    &.slick-active {
      span {
        width: 12px;
        height: 12px;
        background-color: $g-color-white;
      }
    }
  }

  span {
    width: 10px;
    height: 10px;
    border: 1px solid $g-color-white;
    border-radius: 50%;
    background-color: transparent;
  }
}
