/*------------------------------------
	Header Togglers
------------------------------------*/

.u-header-toggler {
	display: block;
	@include px-to-rem(width, 70px);
	@include px-to-rem(height, 50px);
	z-index: 502;

	/* Hamburgers */
	.hamburger {
		line-height: 1;
	}
}


/* Toggler Positioning */
@include header-toggler-positioning();

/* Toggler Positioning (sm) */
@include header-toggler-positioning(sm);

/* Toggler Positioning (md) */
@include header-toggler-positioning(md);

/* Toggler Positioning (lg) */
@include header-toggler-positioning(lg);

/* Toggler Positioning (xl) */
@include header-toggler-positioning(xl);