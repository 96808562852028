/*------------------------------------
  Heading-v1-2
------------------------------------*/
.u-heading-v1-2 {
  &::before {
    top: 48%;
    border-top-style: dashed;
  }
  &::after {
    top: 58%;
    border-top-style: dashed;
  }
}