/*------------------------------------
  Info Block v3-1
------------------------------------*/
/* Title */
.info-v3-1__title {
	position: relative;
	display: inline-block;
	@include px-to-rem(margin-left, 60px);
	@include px-to-rem(margin-right, 60px);

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		display: block;
		@include px-to-rem(width, 50px);
		margin-top: -1px;
		height: 2px;
		background-color: $g-color-white;
	}

	&::before {
		right: 100%;
		margin-right: 10px;
	}

	&::after {
		left: 100%;
		margin-left: 10px;
	}
}