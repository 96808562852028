/*------------------------------------
  Tabs v8
------------------------------------*/
[class*="u-nav-v8"] {
  .nav-link {
    position: relative;
    min-height: 100%;
    padding: 10px 20px;
    background-color: $g-color-gray-dark-v2;
    color: $g-color-white;
    transition: none;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      height: 100%;
      background: {
        image: linear-gradient(to left, $g-color-gray-dark-v2 0%, rgba($g-color-gray-dark-v1, .2) 100%);
        repeat: repeat-y;
      }
      z-index: 1;
    }

    &.active {
      background-color: $g-color-primary;

      &::after {
        display: none;
      }

      .u-nav-v8__icon {
        background-color: $g-color-primary;
      }

      .u-nav-v8__description {
        color: rgba($g-color-white, .7);
      }
    }
  }

  &.u-nav-light {
    .nav-link {
      color: $g-color-gray-dark-v2;

      &.active {
        color: $g-color-main;
        background-color: $g-color-white;

        .u-nav-v8 {
          &__icon {
            color: $g-color-white;
            background-color: $g-color-primary;
          }

          &__title,
          &__description {
            color: $g-color-main;
          }
        }
      }
    }

    .u-nav-v8 {
      &__icon {
        background-color: $g-color-white;
        color: $g-color-white;
      }

      &__title,
      &__description {
        color: $g-color-white;
      }
    }
  }
}

.u-nav-v8 {
  &__icon,
  &__title,
  &__description {
    position: relative;
    z-index: 3;
  }

  &__icon {
    display: none;
    background-color: $g-color-gray-dark-v2;
    color: $g-color-white;
    transform: translateY(-51%);
    transition: none;
  }

  &__title {
    display: block;
  }

  &__description {
    color: inherit;
    font-style: normal;
  }
}

@media (min-width: $g-md) {
  [class*="u-nav-v8"] {
    .nav-link {
      padding: 0 20px 25px;
    }

    &.u-nav-light {
      .nav-link {
        background-color: $g-color-white;

        &::before {
          background-image: linear-gradient(to bottom left, transparent 49.6%, $g-color-white 50%), linear-gradient(to top left, transparent 49.6%, $g-color-white 50%);
        }

        &::after {
          background-image: linear-gradient(to left, $g-color-white 0%, rgba($g-color-gray-light-v2, .2) 100%);
        }

        &.active {
          color: $g-color-white;
          background-color: $g-color-primary;

          &::before {
            background-image: linear-gradient(to bottom left, transparent 49.6%, $g-color-primary 50%), linear-gradient(to top left, transparent 49.6%, $g-color-primary 50%);
          }

          .u-nav-v8 {
            &__title {
              color: $g-color-white;
            }

            &__description {
              color: rgba($g-color-white, .7);
            }
          }
        }
      }

      .u-nav-v8 {
        &__icon,
        &__title,
        &__description {
          color: $g-color-main;
        }
      }
    }

    &.justify-content-end,
    &.text-right {
      .nav-item {
        &:first-child {
          .nav-link {
            &::before {
              display: none;
            }
          }
        }

        &:last-child {
          .nav-link {
            padding: 0 20px 25px;

            &::before {
              display: block;
            }
          }
        }
      }

      .nav-link {
        &::before {
          left: -26px;
          right: auto;
          background: {
            position: top right, bottom left;
            image: linear-gradient(to bottom right, transparent 49.6%, $g-color-gray-dark-v2 50%), linear-gradient(to top right, transparent 49.6%, $g-color-gray-dark-v2 50%);
          }
        }

        &::after {
          left: auto;
          right: 0;
          background-image: linear-gradient(to right, $g-color-gray-dark-v2 0%, rgba($g-color-gray-dark-v1, .2) 100%);
        }

        &.active {
          &::before {
            background-image: linear-gradient(to bottom right, transparent 49.6%, $g-color-primary 50%), linear-gradient(to top right, transparent 49.6%, $g-color-primary 50%);
          }
        }
      }
    }

    &.flex-column {
      .nav-item {
        margin-bottom: 1px;

        &:first-child {
          .nav-link {
            padding: 20px 20px 20px 50px;
          }
        }

        &:last-child {
          .nav-link {
            &::before {
              display: block;
            }
          }
        }
      }

      .nav-link {
        padding: 20px 20px 20px 50px;

        &::before {
          opacity: 0;
        }

        &.active {
          &::before {
            opacity: 1;
          }
        }
      }

      .u-nav-v8 {
        &__icon {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }

  .u-nav-v8 {
    &__icon {
      display: inline-block;
    }

    &__description {
      color: rgba($g-color-white, .5);
    }
  }

  .u-nav-v8-2 {
    .nav-item {
      &:first-child {
        .nav-link {
          padding: 0 20px 25px;
        }
      }

      &:last-child {
        .nav-link {
          &::before {
            display: none;
          }
        }
      }
    }

    .nav-link {
      padding: 0 20px 25px 50px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: -26px;
        display: block;
        width: 26px;
        height: 100%;
        background: {
          repeat: no-repeat;
          size: 100% 50.8%;
          position: top right, bottom left;
          image: linear-gradient(to bottom left, transparent 49.6%, $g-color-gray-dark-v2 50%), linear-gradient(to top left, transparent 49.6%, $g-color-gray-dark-v2 50%);
        }
        z-index: 2;
      }

      &.active {
        &::before {
          background-image: linear-gradient(to bottom left, transparent 49.6%, $g-color-primary 50%), linear-gradient(to top left, transparent 49.6%, $g-color-primary 50%);
        }
      }
    }

    &.justify-content-end,
    &.text-right {
      .nav-item {
        &:first-child {
          .nav-link {
            padding: 0 50px 25px 20px;
          }
        }
      }

      .nav-link {
        padding: 0 50px 25px 20px;
      }
    }
  }
}