/*------------------------------------
  Tabs v7
------------------------------------*/
@media (min-width: $g-md) {
  [class*="u-nav-v7"] {
    .nav-item {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: 0;
        height: 35%;
        border-right: {
          style: solid;
          width: 1px;
          color: $g-color-gray-light-v2;
        }
        transform: translateY(-50%);
      }

      &:last-child {
        &::after {
          border-right-color: transparent;
        }
      }
    }

    .nav-link {
      &.active {
        color: $g-color-primary;
      }
    }

    &.u-nav-dark {
      .nav-link {
        &.active {
          color: $g-color-gray-dark-v2;
        }
      }
    }

    &.u-nav-light {
      .nav-link {
        color: $g-color-white;

        &.active {
          color: $g-color-white;
          opacity: .5;
        }
      }
    }

    &.flex-column {
      .nav-item {
        &::after {
          top: 100%;
          left: 0;
          right: auto;
          width: 35%;
          height: 0;
          border-right: none;
          border-bottom: {
            style: solid;
            width: 1px;
            color: $g-color-gray-light-v2;
          }
          transform: translateY(0);
        }

        &:last-child {
          &::after {
            border-bottom: {
              color: transparent;
            }
          }
        }
      }

      .nav-link {
        padding: {
          left: 0;
          right: 0;
        }
      }

      &.text-center {
        .nav-item {
          &::after {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}
