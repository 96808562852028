/*------------------------------------
  Checkboxes v6
------------------------------------*/

.u-check {
  &-icon {
    &-checkbox,
    &-radio {
      // v6
      &-v6 {
        width: 18px;
        height: 18px;
        font-size: 12px;

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 100%;
          height: 100%;
          border: solid 1px $g-color-gray-light-v2;
          transform: translate(-50%, -50%);

          &::before {
            content: attr(data-uncheck-icon);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      // End v6
    }

    &-checkbox {
      // v6
      // &-v6 {
      //   border-radius: 1px;

      //   i {
      //     border-radius: 1px;
      //   }
      // }
      // End v6
    }

    &-radio {
      // v6
      &-v6 {
        border-radius: 50%;

        i {
          border-radius: 50%;
        }
      }
      // End v6
    }
  }

  // Control
  input[type="checkbox"],
  input[type="radio"] {
    &:checked {
      & +,
      & + * {
        // v6
        .u-check-icon-checkbox-v6,
        .u-check-icon-radio-v6 {
          i {
            color: $g-color-white;
            background-color: $g-color-primary;
            border-color: $g-color-primary;

            &::before {
              content: attr(data-check-icon);
            }
          }
        }
        // End v6
      }
    }
  }
}