/*------------------------------------
  Box-shadows-v20
------------------------------------*/
.u-shadow-v20 {
  box-shadow: 0 10px 10px 0 rgba($g-color-black, .05);

  &--hover {
	  &:hover {
	  	box-shadow: 0 10px 10px 0 rgba($g-color-black, .05);
	  }
	}
}