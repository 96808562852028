/*------------------------------------
  Demo of Icons
------------------------------------*/
.u-icons-demo {

  &__item {
    border: solid 1px $g-color-gray-light-v4;
    margin: 0 -1px -1px 0;
    padding: 20px 15px;
  }

  &-item__value,
  &-item__value:focus {
    width: 100%;
    text-align: center;
    border: none;
    outline: none;
  }
}