/*------------------------------------
  Carousel indicators v4
------------------------------------*/

.u-carousel-indicators-v4 {
  li {
    margin: 0 7px;
    &.slick-active {
      span {
        background-color: $g-color-primary;
      }
    }
  }

  span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $g-color-gray-light-v2;
  }
}
