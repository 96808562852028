/*------------------------------------
  Carousel indicators v33
------------------------------------*/
.u-carousel-indicators-v33 {
	position: relative;
	padding-top: 15px;
  padding-left: 0;

  li {
    display: block;
    color: $g-color-gray-light-v4;
    cursor: pointer;
  	padding: 10px 0;

		&:hover {
			color: rgba($g-color-primary, .5);
		}

    &.slick-active {
      color: $g-color-primary;
    }

    span {
  		display: none;
  	}
  }
}