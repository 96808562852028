.chosen-container-single {
  & .chosen-single div b {
    position: relative;
  }

  & .chosen-search input[type="text"] {
    padding: .8rem 1rem .6rem;
    border-color: $g-color-gray-light-v2;
  }

  &.u-dropdown-sm .chosen-search input[type="text"] {
    padding: .25rem .5rem .1rem;
  }

  &.u-dropdown-lg .chosen-search input[type="text"] {
    padding: .75rem 1.5rem .55rem;
  }

  &.u-select-above .chosen-drop {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: -1px;
  }
}
