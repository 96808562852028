/*------------------------------------
  Carousel indicators v24
------------------------------------*/

.u-carousel-indicators-v24,
.u-carousel-indicators-v24--white {
  white-space: nowrap;

  li {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 5px;

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      border-radius: 50%;
      text-indent: -9999px;

      &::before,
      &::after {
        content: "";
        display: none;
      }

      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        opacity: 0;
        visibility: hidden;
        transition: transform .3s ease .3s, opacity .3s ease, visibility 0s .3s ease;
        transform-origin: -200% 50%;
        transform: rotate(-100deg);
      }
    }

    &.slick-active-right {
      span {
        &::before {
          transition: none;
          transform-origin: 300% 50%;
          transform: rotate(100deg);
        }
      }
    }

    &.slick-current {
      span {
        &::before {
          visibility: visible;
          opacity: 1;
          transition: transform .3s ease, opacity .3s ease;
          transform: rotate(0deg);
        }
      }
    }
  }
}

.u-carousel-indicators-v24--white {
  li {
    span {
      border: 2px solid $g-color-primary;

      &::before {
        background: $g-color-white;
      }

      &:focus {
        background: rgba($g-color-white, .2);
      }
    }
  }
}
