/*------------------------------------
  Time Lines v3
------------------------------------*/

.u-timeline-v3 {
  &-wrap::before {
    left: 21%;
    width: 1px;
    height: 100%;
    display: none;
    background-color: $g-color-gray-light-v4;
  }
}

// [class*="u-timeline-v3-wrap"] {
//   &::before {
//     left: 21%;
//     width: 1px;
//     height: 100%;
//     display: none;
//     background-color: $g-color-gray-light-v2;
//   }
// }

.u-timeline-v3 {
  position: relative;

  &__icon {
    left: 21%;
  }
}

@media (min-width: $g-md) {
  .u-timeline-v3 {
    &-wrap {
      &::before {
        display: block;
      }
    }
  }
}

@media (min-width: $g-lg) {
  .u-timeline-v3 {
    &-wrap {
      &::before {
        left: 18%;
      }
    }

    &__icon {
      left: 18%;
    }
  }
}
