/*--------------------------------------------------
  Info Block v8-1
----------------------------------------------------*/

.u-hs-filter {
  padding: 20px;
  margin-bottom: 0;

  a {
    @include px-to-rem(padding, 7px 15px);
    color: rgba($g-color-white, .8);
    border: solid 1px rgba($g-color-white, .2);
    text-transform: uppercase;
    border-radius: 3px;
    transition: all .3s;

    &:hover,
    &:focus {
      text-decoration: none;
    }
    &:hover {
      color: $g-color-bluegray;
      background-color: rgba($g-color-white, 1);
    }
  }
  .active a {
    color: $g-color-bluegray;
    background-color: rgba($g-color-white, 1);
  }
}