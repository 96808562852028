/*------------------------------------
  Carousel indicators v22
------------------------------------*/

.u-carousel-indicators-v22,
.u-carousel-indicators-v22--white {
  white-space: nowrap;

  li {
    position: relative;
    display: inline-block;
    z-index: 1;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 5px;
    transition: border-color .3s ease;

    span {
      position: absolute;
      bottom: 250%;
      left: 50%;
      width: auto;
      height: auto;
      line-height: 2;
      opacity: 0;
      white-space: nowrap;
      visibility: hidden;
      border-radius: 0;
      padding: 0 10px;
      transform: translateX(-50%) translateY(-50%);
      transition: transform .3s ease, opacity .3s ease, visibility 0s .3s ease;

      &::before,
      &::after {
        content: "";
        display: none;
      }

      &::before {
        display: block;
        position: absolute;
        top: 99%;
        left: 50%;
        width: 0;
        height: 0;
        margin-left: -10px;
        border: 10px solid transparent;
        pointer-events: none;
        backface-visibility: hidden;
        transition: opacity .3s ease;
      }

      &:hover {
        z-index: 11;
        visibility: visible;
        opacity: 1;
        transform: translateX(-50%) translateY(0%);
        transition: transform .3s ease, opacity .3s ease;
      }
    }

    &.slick-active {
      span {
        z-index: 100;
        visibility: visible;
        opacity: 1;
        transform: translateX(-50%) translateY(0%);
        transition: transform .3s ease, opacity .3s ease;
      }

      &:hover {
        span {
          opacity: 1 !important;
        }
      }
    }

    &:hover {
      z-index: 11;
    }
  }

  &:hover {
    li {
      &.slick-active {
        span {
          opacity: .2;
        }
      }
    }
  }
}

.u-carousel-indicators-v22--white {
  li {
    border: 2px solid $g-color-white;

    span {
      color: $g-color-white;
      background-color: $g-color-primary;

      &::before {
        border-top-color: $g-color-primary;
      }
    }

    &.slick-active {
      border-color: $g-color-primary;
    }
  }
}
