/*------------------------------------
  Tabs v2
------------------------------------*/
@media(min-width: $g-md) {
  [class*="u-nav-v2"] {
    .nav-link {
      border: {
        style: solid;
        color: $g-color-gray-light-v4;
        width: 1px;
      }
      margin-left: -1px;
    }
    .nav-item:first-child .nav-link {
      margin-left: 0;
    }

    &.flex-column .nav-link {
      margin: -1px 0 0;
    }
    &.flex-column .nav-item:first-child .nav-link {
      margin-top: 0;
    }

    &.u-nav-primary {
      .nav-link {
        border-color: $g-color-primary;
      }

      .nav-link.active,
      .cbp-filter-item-active .nav-link {
        border-color: $g-color-primary !important;
      }
    }

    &.u-nav-dark {
      .nav-link {
        border-color: $g-color-gray-dark-v2;
      }

      .nav-link.active,
      .cbp-filter-item-active .nav-link {
        border-color: $g-color-gray-dark-v2 !important;
      }
    }

    &.u-nav-light {
      .nav-link {
        color: $g-color-white;
        border-color: $g-color-white;
      }

      .nav-link.active,
      .cbp-filter-item-active .nav-link {
        border-color: $g-color-white !important;
      }
    }

    &.u-nav-rounded-3 .nav-item:first-child .nav-link {
      border-radius: 3px 0 0 3px;
    }
    &.u-nav-rounded-3 .nav-item:last-child .nav-link {
      border-radius: 0 3px 3px 0;
    }

    &.u-nav-rounded-3.flex-column .nav-item:first-child .nav-link {
      border-radius: 3px 3px 0 0;
    }
    &.u-nav-rounded-3.flex-column .nav-item:last-child .nav-link {
      border-radius: 0 0 3px 3px;
    }

    &.u-nav-rounded-5 .nav-item:first-child .nav-link {
      border-radius: 5px 0 0 5px;
    }
    &.u-nav-rounded-5 .nav-item:last-child .nav-link {
      border-radius: 0 5px 5px 0;
    }

    &.u-nav-rounded-5.flex-column .nav-item:first-child .nav-link {
      border-radius: 5px 5px 0 0;
    }
    &.u-nav-rounded-5.flex-column .nav-item:last-child .nav-link {
      border-radius: 0 0 5px 5px;
    }

    &.u-nav-rounded-7 .nav-item:first-child .nav-link {
      border-radius: 7px 0 0 7px;
    }
    &.u-nav-rounded-7 .nav-item:last-child .nav-link {
      border-radius: 0 7px 7px 0;
    }

    &.u-nav-rounded-7.flex-column .nav-item:first-child .nav-link {
      border-radius: 7px 7px 0 0;
    }
    &.u-nav-rounded-7.flex-column .nav-item:last-child .nav-link {
      border-radius: 0 0 7px 7px;
    }

    &.u-nav-rounded-10 .nav-item:first-child .nav-link {
      border-radius: 10px 0 0 10px;
    }
    &.u-nav-rounded-10 .nav-item:last-child .nav-link {
      border-radius: 0 10px 10px 0;
    }

    &.u-nav-rounded-10.flex-column .nav-item:first-child .nav-link {
      border-radius: 10px 10px 0 0;
    }
    &.u-nav-rounded-10.flex-column .nav-item:last-child .nav-link {
      border-radius: 0 0 10px 10px;
    }
  }

  .u-nav-v2-1 {
    .nav-link.active,
    .cbp-filter-item-active .nav-link {
      background-color: $g-color-gray-light-v4;
    }

    &.u-nav-primary .nav-link.active,
    &.u-nav-primary .cbp-filter-item-active .nav-link {
      color: $g-color-white;
      background-color: $g-color-primary;
    }

    &.u-nav-dark .nav-link.active,
    &.u-nav-dark .nav-link.cbp-filter-item-active {
      color: $g-color-white;
      background-color: $g-color-gray-dark-v2;
    }

    &.u-nav-light .nav-link.active,
    &.u-nav-light .nav-link.cbp-filter-item-active {
      color: $g-color-gray-dark-v2;
      background-color: $g-color-white;
    }
  }

  .u-nav-v2-2 {
    .nav-link.active,
    .cbp-filter-item-active .nav-link {
      color: $g-color-primary;
    }
  }
}
