//
// Paddings
//

@mixin padding-spaces($media-type) {

  $media-value: 0;

  @if $media-type == xs {
    $media-value: 0;
    $media-type: '';
  } @else if $media-type == sm {
    $media-value: $g-sm;
    $media-type: --sm;
  } @else if $media-type == md { /* P */
    $media-value: $g-md;
    $media-type: --md;
  } @else if $media-type == lg { /* P */
    $media-value: $g-lg;
    $media-type: --lg;
  } @else {
    $media-value: $g-xl;
    $media-type: --xl;
  }

  @media (min-width: #{$media-value}) {
    // Zeroing
    .g-pa-0#{$media-type} {
      padding: 0 !important;
    }
    .g-px-0#{$media-type} {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .g-py-0#{$media-type} {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .g-pt-0#{$media-type} {
      padding-top: 0 !important;
    }
    .g-pr-0#{$media-type} {
      padding-right: 0 !important;
    }
    .g-pb-0#{$media-type} {
      padding-bottom: 0 !important;
    }
    .g-pl-0#{$media-type} {
      padding-left: 0 !important;
    }

    /* Padding Around */
    .g-pa-2#{$media-type} {
      @include px-to-rem(padding, 2px !important);
    }
    .g-pa-3#{$media-type} {
      @include px-to-rem(padding, 3px !important);
    }
    .g-pa-5#{$media-type} {
      @include px-to-rem(padding, 5px !important);
    }
    .g-pa-7#{$media-type} {
      @include px-to-rem(padding, 7px !important);
    }

    $i: 10;
    @while $i < 151 {
      .g-pa-#{$i}#{$media-type} {
        @include px-to-rem(padding, $i*1px !important);
      }
      $i: $i + 5;
    }

    /* Padding X */
    $i: 1;
    @while $i < 20 {
      .g-px-#{$i}#{$media-type} {
        @include px-to-rem(padding-left, $i*1px !important);
        @include px-to-rem(padding-right, $i*1px !important);
      }
      $i: $i + 1;
    }

    $i: 10;
    @while $i < 151 {
      .g-px-#{$i}#{$media-type} {
        @include px-to-rem(padding-left, $i*1px !important);
        @include px-to-rem(padding-right, $i*1px !important);
      }
      $i: $i + 5;
    }

    /* Padding Y */
    $i: 1;
    @while $i < 20 {
      .g-py-#{$i}#{$media-type} {
        @include px-to-rem(padding-top, $i*1px !important);
        @include px-to-rem(padding-bottom, $i*1px !important);
      }
      $i: $i + 1;
    }

    $i: 10;
    @while $i < 211 { /* P */
      .g-py-#{$i}#{$media-type} {
        @include px-to-rem(padding-top, $i*1px !important);
        @include px-to-rem(padding-bottom, $i*1px !important);
      }
      $i: $i + 5;
    }

    /* Padding Top */
    $i: 0;
    @while $i < 20 {
      .g-pt-#{$i}#{$media-type} {
        @include px-to-rem(padding-top, $i*1px !important);
      }
      $i: $i + 1;
    }

    $i: 10;
    @while $i < 151 {
      .g-pt-#{$i}#{$media-type} {
        @include px-to-rem(padding-top, $i*1px !important);
      }
      $i: $i + 5;
    }

    /* Padding Right */
    $i: 0;
    @while $i < 10 {
      .g-pr-#{$i}#{$media-type} {
        @include px-to-rem(padding-right, $i*1px !important);
      }
      $i: $i + 1;
    }

    $i: 10;
    @while $i < 171 { /* P */
      .g-pr-#{$i}#{$media-type} {
        @include px-to-rem(padding-right, $i*1px !important);
      }
      $i: $i + 5;
    }

    /* Padding Bottom */
    $i: 0;
    @while $i < 10 {
      .g-pb-#{$i}#{$media-type} {
        @include px-to-rem(padding-bottom, $i*1px !important);
      }
      $i: $i + 1;
    }

    $i: 10;
    @while $i < 151 {
      .g-pb-#{$i}#{$media-type} {
        @include px-to-rem(padding-bottom, $i*1px !important);
      }
      $i: $i + 5;
    }

    /* Padding Left */
    $i: 0;
    @while $i < 10 {
      .g-pl-#{$i}#{$media-type} {
        @include px-to-rem(padding-left, $i*1px !important);
      }
      $i: $i + 1;
    }

    $i: 10;
    @while $i < 171 { /* P */
      .g-pl-#{$i}#{$media-type} {
        @include px-to-rem(padding-left, $i*1px !important);
      }
      $i: $i + 5;
    }
  }
}