/*------------------------------------
  Selects
------------------------------------*/

.input-group {
  select {
    -webkit-appearance: none;
  }
}

//
// Imports
//

@import "selects-v1";
@import "selects-v2";
