/*------------------------------------
  Text Animation Slideshow
------------------------------------*/
.u-text-slideshow {
	position: relative;

	overflow: visible !important;
	vertical-align: inherit;
}

.u-text-slideshow__slide {
	position: relative;
	z-index: 1;

	display: inline-block;

	opacity: 0;
	transition: .3s ease;

	&:not(:first-child) {
		@include absolute(top 0 left 0);
	}
	&--current {
		z-index: 2;
		opacity: 1;
	}

	&-target{
		flex-wrap: nowrap;
	}
}