//
// Button Hovers v1, v2
//

// e.g. @include u-hover-btn-v1 (1, 1, $g-color-primary);
@mixin u-button-flat-hover ($version, $type, $background) {
  &.u-btn-hover-v#{$version}-#{$type} {
    overflow: hidden;

    &::after,
    &:hover::after {
      background-color: lighten($background, 5%);
    }

    &:hover {
      background-color: $background;
      overflow: hidden;
    }
  }
}

// e.g. @include u-hover-btn-v1 (1, 1, $g-color-primary);
@mixin u-button-outline-hover ($version, $type, $background) {
  &.u-btn-hover-v#{$version}-#{$type} {
    overflow: hidden;

    &::after,
    &:hover::after {
      background-color: lighten($background, 5%);
    }

    &:hover {
      overflow: hidden;
      background-color: transparent;
    }
  }
}