/*------------------------------------
  Navigation Dropdown Style v5
------------------------------------*/

.u-dropdown-v5 {
  display: block;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity .3s, max-height .3s;

  &.show {
    max-height: 200px;
    opacity: 1;
  }
}