.g-order {
  &-1 {
    order: 1;
  }

  &-2 {
    order: 2;
  }
}

@media (min-width: $g-sm) {
  .g-order {
    &-1--sm {
      order: 1;
    }

    &-2--sm {
      order: 2;
    }
  }
}

@media (min-width: $g-md) {
  .g-order {
    &-1--md {
      order: 1;
    }

    &-2--md {
      order: 2;
    }
  }
}

@media (min-width: $g-lg) {
  .g-order {
    &-1--lg {
      order: 1;
    }

    &-2--lg {
      order: 2;
    }
  }
}
