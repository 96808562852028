/*------------------------------------
  Basket Bar
------------------------------------*/

/* Container */
.u-basket {
	position: relative;
	z-index: 1;
}

/* Bar */
.u-basket__bar {
	position: absolute;
	right: 0;
	top: 100%;
	text-align: left;
	@include px-to-rem(width, 300px);
	background-color: $g-color-white;
	box-shadow: 0 5px 5px 0px rgba(90,90,90, .075);
}

/* Product */
.u-basket__product {
	position: relative;
	z-index: 1;
	overflow: hidden;
	@include px-to-rem(padding, 10px);
	border-bottom: solid 1px $g-color-gray-light-v4;
}

/* Product Image */
.u-basket__product-img {
	@include px-to-rem(width, 100px);

	img {
		width: 100%;
	}
}

/* Product Remove Button */
.u-basket__product-remove {
	@include absolute(top 20px right 20px);
	padding: 0;
	margin: 0;
	@include px-to-rem(font-size, 21px);
	font-family: inherit;
	font-weight: 700;
	color: $g-color-black;
	border: none;
	background-color: transparent;
	cursor: pointer;
	opacity: .2;

	&:hover,
	&:focus {
		opacity: 1;
	}
}