/*------------------------------------
  Quotes
------------------------------------*/
/* Quote v1 */
.u-quote-v1 {
  &::before {
    content: "\“";
    font-size: 36px;
    line-height: 0.75em;
    text-align: center;
    font-weight: 600;
    display: block;
  }

  &::after {
    display: none;
  }
}
