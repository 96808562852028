/*------------------------------------
  Chips
------------------------------------*/
.u-chip {
	display: inline-block;
	@include px-to-rem(height, 32px);
	@include px-to-rem(margin, 2px 0);
	@include px-to-rem(padding, 0 12px);
	font-size: 0;
	white-space: nowrap;
	@include px-to-rem(line-height, 32px);
	color: rgba($g-color-black, .87);
	@include px-to-rem(border-radius, 16px);
	background-color: $g-color-gray-light-v4;

	&:focus {
		box-shadow: 0 2px 2px 0 rgba($g-color-black, .14), 0 3px 1px -2px rgba($g-color-black, .2), 0 1px 5px 0 rgba($g-color-black, .12);
	}
	// &:active {
	// 	background-color: $g-color-gray-light-v3;
	// }
	&--deletable {
		@include px-to-rem(padding-right, 4px);
	}
	&--contact {
		padding-left: 0;
	}

	&__contact {
		display: inline-block;
		@include px-to-rem(width, 32px);
		@include px-to-rem(height, 32px);
		@include px-to-rem(margin-right, 8px);
		overflow: hidden;
		vertical-align: middle;
		@include px-to-rem(border-radius, 16px);
		text-align: center;
		@include px-to-rem(font-size, 18px);
		@include px-to-rem(line-height, 32px);
	}
	&__text {
		display: inline-block;
		vertical-align: middle;
		@include px-to-rem(font-size, 13px);
	}
	&__action {
		display: inline-block;
		@include px-to-rem(width, 24px);
		@include px-to-rem(height, 24px);
		@include px-to-rem(margin, 0 0 0 4px);
		padding: 0;
		text-align: center;
		vertical-align: middle;
		border: none;
		@include px-to-rem(font-size, 13px);
		text-decoration: none;
		color: rgba($g-color-black, .3);
		background: transparent;
		opacity: .54;
		cursor: pointer;
		outline: none;
		overflow: hidden;
	}
}