.u-outer-spaces-helper {
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100vh;
    background-color: $g-color-gray-light-v5;
    background-position: center;
    background-repeat: repeat;

    .g-dark-theme & {
      background-color: $g-color-black;
    }

    [class*="g-bgi-v"] & {
      background-color: transparent;
    }
  }

  .u-outer-space-v1 &,
  .u-outer-space-v2 & {
    display: block;
  }

  .u-outer-space-v1 & {
    height: 20px;
  }

  .u-outer-space-v2 & {
    height: 40px;
  }
}
