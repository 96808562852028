/*------------------------------------
  Social Border Colors
------------------------------------*/
/* Facebook */
.g-brd-facebook {
  border-color: $g-color-facebook;

  &--hover:hover {
    border-color: $g-color-facebook !important;
  }
}

/* Twitter */
.g-brd-twitter {
  border-color: $g-color-twitter;

  &--hover:hover {
    border-color: $g-color-twitter !important;
  }
}

/* Skype */
.g-brd-skype {
  border-color: $g-color-skype;

  &--hover:hover {
    border-color: $g-color-skype !important;
  }
}

/* Pinterest */
.g-brd-pinterest {
  border-color: $g-color-pinterest;

  &--hover:hover {
    border-color: $g-color-pinterest !important;
  }
}

/* Vine */
.g-brd-vine {
  border-color: $g-color-vine;

  &--hover:hover {
    border-color: $g-color-vine !important;
  }
}

/* Youtube */
.g-brd-youtube {
  border-color: $g-color-youtube;

  &--hover:hover {
    border-color: $g-color-youtube !important;
  }
}

/* Google plus */
.g-brd-google-plus {
  border-color: $g-color-google-plus;

  &--hover:hover {
    border-color: $g-color-google-plus !important;
  }
}

/* Dribbble */
.g-brd-dribbble {
  border-color: $g-color-dribbble;

  &--hover:hover {
    border-color: $g-color-dribbble !important;
  }
}

/* VK */
.g-brd-vk {
  border-color: $g-color-vk;

  &--hover:hover {
    border-color: $g-color-vk !important;
  }
}

/* Linkedin */
.g-brd-linkedin {
  border-color: $g-color-linkedin;

  &--hover:hover {
    border-color: $g-color-linkedin !important;
  }
}

/* Instagram */
.g-brd-instagram {
  border-color: $g-color-instagram;

  &--hover:hover {
    border-color: $g-color-instagram !important;
  }
}