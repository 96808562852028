/*------------------------------------
  Slide Effect v2
------------------------------------*/
.u-icon__elem-regular,
.u-icon__elem-hover {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  transition: all .3s ease-in-out;
}

.u-icon__elem-hover {
  position: absolute !important;
}

.u-icon-slide-up--hover,
.u-icon-slide-down--hover,
.u-icon-slide-left--hover,
.u-icon-slide-right--hover {
  overflow: hidden;
}

// Hover slide-v2--up
.u-icon-slide-up--hover .u-icon__elem-regular {
  transform: translateY(-20%);

  &.u-line-icon-pro {
    transform: translateY(-30%);
  }
}

.u-icon-slide-up--hover .u-icon__elem-hover {
  transform: translateY(110%);

  &.u-line-icon-pro {
    transform: translateY(120%);
  }
}

.u-icon-slide-up--hover:hover .u-icon__elem-regular,
.u-icon-block--hover:hover .u-icon-slide-up--hover .u-icon__elem-regular {
  transform: translateY(-110%);

  &.u-line-icon-pro {
    transform: translateY(-120%);
  }
}

.u-icon-slide-up--hover:hover .u-icon__elem-hover,
.u-icon-block--hover:hover .u-icon-slide-up--hover .u-icon__elem-hover {
  transform: translateY(-20%);

  &.u-line-icon-pro {
    transform: translateY(-30%);
  }
}

// Hover slide-v2--down
.u-icon-slide-down--hover .u-icon__elem-regular {
  transform: translateY(-110%);

  &.u-line-icon-pro {
    transform: translateY(-120%);
  }
}

.u-icon-slide-down--hover .u-icon__elem-hover {
  transform: translateY(-20%);

  &.u-line-icon-pro {
    transform: translateY(-30%);
  }
}

.u-icon-slide-down--hover:hover .u-icon__elem-regular,
.u-icon-block--hover:hover .u-icon-slide-down--hover .u-icon__elem-regular {
  transform: translateY(-20%);

  &.u-line-icon-pro {
    transform: translateY(-30%);
  }
}

.u-icon-slide-down--hover:hover .u-icon__elem-hover,
.u-icon-block--hover:hover .u-icon-slide-down--hover .u-icon__elem-hover {
  transform: translateY(80%);

  &.u-line-icon-pro {
    transform: translateY(90%);
  }
}

// Hover slide-v2--left
.u-icon-slide-left--hover .u-icon__elem-regular {
  transform: translate(0, -20%);

  &.u-line-icon-pro {
    transform: translate(0, -30%);
  }
}

.u-icon-slide-left--hover .u-icon__elem-hover {
  transform: translate(110%, -20%);

  &.u-line-icon-pro {
    transform: translate(110%, -30%);
  }
}

.u-icon-slide-left--hover:hover .u-icon__elem-regular,
.u-icon-block--hover:hover .u-icon-slide-left--hover .u-icon__elem-regular {
  transform: translate(-110%, -20%);

  &.u-line-icon-pro {
    transform: translate(-110%, -30%);
  }
}

.u-icon-slide-left--hover:hover .u-icon__elem-hover,
.u-icon-block--hover:hover .u-icon-slide-left--hover .u-icon__elem-hover {
  transform: translate(0, -20%);

  &.u-line-icon-pro {
    transform: translate(0, -30%);
  }
}

// Hover slide-v2--right
.u-icon-slide-right--hover .u-icon__elem-regular {
  transform: translate(-110%, -20%);

  &.u-line-icon-pro {
    transform: translate(-110%, -30%);
  }
}

.u-icon-slide-right--hover .u-icon__elem-hover {
  transform: translate(0, -20%);

  &.u-line-icon-pro {
    transform: translate(0, -30%);
  }
}

.u-icon-slide-right--hover:hover .u-icon__elem-regular,
.u-icon-block--hover:hover .u-icon-slide-right--hover .u-icon__elem-regular {
  transform: translate(0, -20%);

  &.u-line-icon-pro {
    transform: translate(0, -30%);
  }
}

.u-icon-slide-right--hover:hover .u-icon__elem-hover,
.u-icon-block--hover:hover .u-icon-slide-right--hover .u-icon__elem-hover {
  transform: translate(110%, -20%);

  &.u-line-icon-pro {
    transform: translate(110%, -30%);
  }
}