/* Add here all your css styles (customizations) */

// Variables
@import "settings/variables";
@import "custom.variables";

// Mixins
@import "custom.mixins";

@media (min-width: 1200px) {

    .container {
        max-width: 1290px;
    }
}

body {
    color: #2c2c2c;
}

.hs-sub-menu {
    background: #51a236 !important;
}

body.service-detail {
    color: #2c2c2c;
    background: url("/../../../content/assets/img/bg/bg.jpg") no-repeat center top #edefee;
}

.img-responsive {
    width: 100%;
}

.home-welcome h1 span {
    color: #2c2c2c;
}

.service-detail .home-welcome h1 {
    font-weight: 500;
}

.home-welcome h2 {
    font-weight:500;
}

.service-detail .home-welcome h2 {
    font-size: 32px;
}

.home-welcome p {
    color: #6a6c6c;
}

.home-welcome .row {
    align-items: flex-end;
}
.breadcrumb {
    margin-bottom: 0;
}

.hs-has-sub-menu > a::after {
    font-size: 0;
    display: none;
    margin-left: 0;
}
 
.hs-sub-menu .nav-item.g-ml-45--xl {
    margin-left: 0 !important;
}

.g-bg-primary {
    background: #5aac3e !important;
}

.hs-sub-menu {
    background: #5aac3e !important;
    padding: 10px 15px !important;
}

.hs-sub-menu a {
    color: #fff !important;
    font-size: 1.0em !important;
}

.hs-sub-menu a:hover {
    color: #fff !important;
    font-size: 1.0em !important;
    text-decoration: underline !important;
}

.dropdown-menu {
    background: #5aac3e !important;
    padding: 10px 15px !important;
    border: 0 !important;
}

.dropdown-menu a {
    color: #fff !important;
    text-transform: uppercase !important;
    font-size: 1.0em !important;
}

.dropdown-item a:hover {
    text-decoration: underline !important;
}

.dropdown-item:hover {
    background: #5aac3e;
}

#loginBar {
    margin-top: 12px;
}

.dropdown-item {
    padding: .5rem 0 !important;
}

.our-services-sub .our-services .col-md-6 {
    padding-top: 0;
    padding-bottom: 0;
}

.our-services-sub .our-services .col-md-6:hover {
    background: none !important;
}

.our-services-sub .our-services .media:hover h3 {
    color: #fff !important;
}

.our-services-sub .our-services .media {
    min-height: 1px;
}


.text-center {
    text-align: center !important;
}

.Threesteps h2 {
    font-size: 68px;
    font-weight: 600;
    line-height: 1.2em !important;
    color: #2c2c2c;
}

.callus span {
    font-size: 17px !important;
    display: block;
    text-transform: uppercase;
    line-height: 1.0em !important;
}

.Threesteps .callus {
    line-height: 1.2em !important;
}

.Threesteps .callus span {
    font-size: 32px !important;
    display: block;
    color: #2c2c2c;
    text-transform: none;
    line-height: 1.3em !important;
}

.home-welcome .callus {
    margin-top: 4px;
    padding-left: 7px;
}

.callus a {
    font-size: 42px !important;
    line-height: 1.0em !important;
}


.Threesteps .callus a {
    font-size: 46px !important;
    line-height: 1.0em !important;
}

.btn.btn--primary {
    font-size: 22px !important;
    font-weight: 500 !important;
    line-height: 1.0em !important;
    background: #5aac3e !important;
    color: #fff !important;
    padding: 15px 15px !important;
    border-radius: 32px !important;
    width: 100% !important;
}

.btn.btn--primary:hover {
    background: #3e9122 !important;
}

.Threesteps .btn.btn--primary {
    width: 340px !important;
}

.Threesteps {
    background: url("/../../../content/assets/img/threesteps.png") no-repeat center bottom #dfe3e4;
}

.step1, .step2, .step3 {
    color: #636565;
}

.why-profi {
    background: #5aac3e;
}

.why-profi .row {
    background: url("/../../../content/assets/img/bgwhy.png") no-repeat 0 0;
}

.why-profi h2 {
    font-size: 64px;
    font-weight: 600;
    line-height: 1.2em;
}

.why-profi p {
    font-size: 19px;
    font-weight: 400;
}

.why-profi ul {
    margin-left: 0;
    padding-left: 0;
}

.why-profi ul li {
    background: url("/../../../content/assets/img/done.png") no-repeat 0 11px;
    padding: 6px 10px 6px 34px;
    list-style-type: none;
    color: #2c2c2c;
    font-size: 19px;
    margin-left: 0;
    font-weight: 500;
}

.step1 span {
    background: url("/../../../content/assets/img/bg01.png") no-repeat;
    font-size: 28px;
    color: #6cab46;
    display: block;
    padding-top: 33px;
    margin-bottom: 14px;
    line-height: 1.0em;
    font-weight: 500;
}

.step2 span {
    background: url("/../../../content/assets/img/bg02.png") no-repeat;
    font-size: 28px;
    color: #6cab46;
    display: block;
    font-weight: 500;
    padding-top: 33px;
    margin-bottom: 14px;
    line-height: 1.0em;
}

.step3 span {
    background: url("/../../../content/assets/img/bg03.png") no-repeat;
    font-size: 42px;
    color: #6cab46;
    display: block;
    padding-top: 33px;
    margin-bottom: 14px;
    line-height: 1.0em;
    font-weight: 500;
}

.step1, .step2, .step3 {
    font-size: 16px;
}

.testimonials .js-slide p:last-child {
    line-height: 1.8em;
}

.testimonials .js-slide p:first-child {
    color: #686a6a;
}

.content p img {
    margin: 0 10px
}

    .u-header {
        font-size: 1.0rem;
    }

    .bg-abstract {
        background-image: url("../../../content/assets/img/bg/mirrored-squares.png");
    }

    body.homepage {
        background: url("/../../../content/assets/img/bg/bghome.jpg") no-repeat center top #edefee;
    }

    .fa-pull-right, .pull-right, .fa-pull-right li, .pull-right li, .loginbar li {
        list-style-type: none;
    }

    .loginbar {
        margin-top: 8px;
        margin-left: 15px
    }

    .g-hidden-md-up {
        .loginbar {
            & > li > a {
                color: #fff;
            }

            & li a {
                padding: 1.07143rem;
                display: inline-block;
            }

            & .dropdown-menu {
                width: 100%;

                & li {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }

            padding: 0;
            margin: 0;
        }
    }

    .u-link-v5 .fa-angle-right {
        display: block;
        float: left;
        margin: 4px 0 6px 8px;
    }

    .img-responsive.full-width {
        width: 100%;
    }

    .input-validation-error,
    .input-validation-error:focus {
        border-color: $danger-color !important;
    }

    .field-validation-error {
        color: $danger-color !important;
    }

    .validation-summary-errors {
        position: relative;
        padding: .75rem 1.25rem;
        margin: 0 0 .75rem 0 !important;
        border: 1px solid transparent;
        border-radius: .25rem;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb
    }

    .validation-summary-errors ul {
        margin: 0;
        padding: 0 0 0 15px;
        list-style: none
    }

    .dropdown-menu {
        padding: 15px;
        border-radius: 0
    }

    .user-logged:hover {
        text-decoration: none
    }

    @include breakpoint(992up) {
        .min-height-article {
            min-height: 280px;
        }

        .masonry-grid-item > article {
            height: 100%
        }
    }

    @include breakpoint(phone) {
        .navbar-collapse {
            background-color: $g-color-primary;
        }

        /*.navbar-collapse .hs-sub-menu {
            background-color: rgba(255, 255, 255, 0.2);
        }*/

        .g-color-white-xs, .nav-link.g-color-white-xs {
            color: #fff !important;
        }
    }

    .highlighted {
        font-weight: bold;
        background: #F0F0F0;
    }

    /* loader for jobs list loading - copied from stengl*/
    .ajax-preloader {
        display: block;
        width: 100%;
        float: left;
        text-align: center;
        position: relative;
        height: 100px;
        margin: 15px 0
    }

    @keyframes lds-rolling {
        0% {
            transform: translate(-50%,-50%) rotate(0)
        }

        100% {
            transform: translate(-50%,-50%) rotate(360deg)
        }
    }

    .lds-rolling {
        position: relative;
        display: inline-block
    }

    .lds-rolling div, .lds-rolling div:after {
        position: absolute;
        width: 90px;
        height: 90px;
        border: 10px solid #213769;
        border-top-color: transparent;
        border-radius: 50%
    }

    .lds-rolling div {
        animation: lds-rolling 1s linear infinite;
        top: 100px;
        left: 100px
    }

    .lds-rolling div:after {
        -ms-transform: rotate(90deg);
        transform: rotate(90deg)
    }

    .lds-rolling {
        width: 100px !important;
        height: 100px !important;
        -ms-transform: translate(-50px,-50px) scale(.5) translate(50px,50px);
        transform: translate(-50px,-50px) scale(.5) translate(50px,50px)
    }

    .hidden {
        display: none;
    }

    .dropdown-menu li {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    .alert > ul {
        margin-bottom: 0
    }

    .alert .fa {
        margin-right: 3px
    }

    ul.ui-autocomplete {
        position: absolute;
        z-index: 510;
        background: white;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.09), -2px 2px 3px rgba(0, 0, 0, 0.09);
        border-radius: 0 0 .25rem .25rem !important;
        text-align: left;
        list-style: none;
        padding-left: 0;

        li {
            margin: 0;
            cursor: pointer;

            &.ui-menu-item {
                padding: 2px 4px;
            }

            .text {
                color: $g-color-primary;
            }

            .category {
                color: lightgray;
            }

            .annotation {
                color: #555;
                word-wrap: normal;
            }

            &.ui-state-hover,
            &.ui-state-focus,
            &.ui-menu-item:hover {
                /*background: $g-color-gray-light-v5!important;*/
                border-top: none !important;
                border-left: none !important;
                border-right: none !important;
                font-weight: normal !important;
            }
        }
    }



    .accordion-status {
        float: left;
        margin-top: 4px;
        margin-right: 6px;
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 4px;
    }

    .accordion-status.ok {
        background: #01b50a;
    }

    .accordion-status.error {
        background: #b50101;
    }

    .status-text {
        color: #777777;
        float: right;
        margin-right: 12px;
    }

    .service-description ul li::before {
        content: "\e080";
        font-family: 'simple-line-icons';
        font-style: normal;
        font-weight: 400;
        margin-right: .71429rem !important;
        color: #bd3c3d !important;
    }

    .service-description ul li::before {
        content: "\e080";
        font-family: 'simple-line-icons';
        font-style: normal;
        font-weight: 400;
        margin-right: .71429rem !important;
        color: #bd3c3d !important;
    }

    .area-height {
        height: 80px;
    }

    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        font-family: 'Rubik', sans-serif;
        font-weight: 300;
    }

    html, body {
        font-family: 'Rubik', sans-serif;
    }

    h1 {
        font-weight: 600;
        color: #52ae32 !important;
        font-size: 2.85714rem !important;
    }

.breadcrumb .g-color-black, .breadcrumb i {
    color: #6d6f6f !important;
}

.breadcrumb {
    background: none !important;
    font-weight: 200 !important;
}

    .js-header-fix-moment .g-width-150 {
        width: 90px !important;
    }

    .u-accordion__control-icon {
        float: right !important;
    }

    .g-brd-top-menu {
        border-top: 1px solid #eee;
    }


    .u-accordion-bg-secondary .u-accordion__header [aria-expanded="true"] {
        background-color: #f0feeb !important;
        border-color: #f0feeb !important;
    }

    .our-services .u-icon-v2.g-color-primary {
        color: #000 !important;
        border-color: none !important;
    }

    .our-services h3 a {
        color: #373d41 !important;
    }

    .why-title h2 {
        width: 90%;
        line-height: 1.2;
    }

    .why-title img {
        width: 56%;
    }



.footer-menu a {
    text-transform: none !important;
}


    .service-description ul li::before {
        color: #52ae32 !important;
    }

    .service-description ul:first-child {
        margin-left: 30px !important;
    }

    .service-description h3 {
        font-weight: bold;
        font-size: 1.45rem;
    }

    .service-price li:before {
        content: "\f00c";
        margin-right: 6px;
        margin-top: 4px;
        color: #52ae32 !important;
        font: normal normal normal 14px/1 FontAwesome;
    }

    .service-price h2 {
        font-size: 1.85714rem !important;
        margin-bottom: 20px;
        font-weight: 600 !important;
    }

    .service-price-inner {
        padding: 30px !important;
        background: #f3fdef !important;
    }

    .service-price .text-center {
        padding: 10px 10px 10px !important;
        background: #f3fdef !important;
    }

    .service-price2 img, .faq img {
        margin-bottom: 20px;
    }

    .services-why h2, .service-price2 h2, .faq h2 {
        font-size: 2.25714rem !important;
        margin-bottom: 20px;
        font-weight: 600 !important;
        line-height: 1.2;
    }

.faq h2 {
    font-size: 45px !important;
}

.faq a {
    color: #2c2c2c !important;
    font-weight: 400 !important;
}

.faq .u-accordion__body.g-color-gray-dark-v5 {
    color: #7f7f7f !important;
}

.faq .u-accordion__body {
    padding: 1.07143rem 1.57143rem;
    font-size: 1.25rem;
}

.faq [aria-expanded="false"] .u-accordion__control-icon i:nth-child(1) {
    display: inline-block;
}

[aria-expanded="true"] .u-accordion__control-icon i:nth-child(2) {
    display: inline-block;
}

.fa-angle-down::before {
    content: "\f067";
    color: #52ae32 !important;
}

.fa-angle-up::before {
    content: "\f068";
    color: #52ae32 !important;
}

.service-price2 {
    background: #f3fdef !important;
}

.realized {
    background: #e5e9eb !important;
}

.realized h2 {
    font-size: 37px;
}

.service-price2 li::before {
    content: "\f00c";
    margin-right: 6px;
    margin-top: 4px;
    color: #52ae32 !important;
    font: 14px/1 FontAwesome;
}

    .services-why h3 {
        font-size: 1.15rem !important;
    }

    .services-why h2 {
        margin-bottom: 40px;
    }

    .komin {
        background-image: url("/../../../content/assets/img/icons/komin.png");
        background-size: 50px 50px;
        background-repeat: no-repeat;
        border: 0 !important;
        display: block;
        width: 50px;
        height: 50px;
    }

    .kotol {
        background-image: url("/../../../content/assets/img/icons/kotol.png");
        background-size: 50px 50px;
        background-repeat: no-repeat;
        border: 0 !important;
        display: block;
        width: 50px;
        height: 50px;
    }

    .bleskozvod {
        background-image: url("/../../../content/assets/img/icons/bleskozvod.png");
        background-size: 50px 50px;
        background-repeat: no-repeat;
        border: 0 !important;
        display: block;
        width: 50px;
        height: 50px;
    }

    .rozvod {
        background-image: url("/../../../content/assets/img/icons/rozvod.png");
        background-size: 50px 50px;
        background-repeat: no-repeat;
        border: 0 !important;
        display: block;
        width: 50px;
        height: 50px;
    }

    .form-control {
        &[data-val-required] {
            background-color: $required-bg-color;
        }
    }

    .display-inline {
        display: inline-block;
    }

.our-services .media {
    text-align: center;
    display: block;
    min-height: 232px;
}

    .our-services .media h3 {
        color: #fff !important;
    }

.our-services .media:hover {
    text-decoration: none;
    color: #fff !important;
}

.our-services .col-md-6 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.our-services .col-md-6:hover {
    text-decoration: none;
/*    border-radius: 6px;
    background: #499c2d !important;*/
}

.our-services .media:hover p, .our-services .col-md-6:hover p {
    color: #fff !important;
}

    .our-services .btn.btn-more {
        padding: 0 !important;
        margin: 0 !important;
        text-transform: uppercase;
    }

    .our-services .media .media-ico {
        height: 83px !important;
        margin-bottom: 22px !important;
    }

.media p {
    color: #d8edd0 !important;
}

.form-group .media p {
    color: #499a2c !important;
}


.form-group .media p strong {
    font-weight: 500;
}


.imgkomin {
    background: url("/../../../content/assets/img/sluzby_komin.svg") no-repeat top center;
}

   /* .our-services .media:hover .imgkomin {
        background: url("/../../../content/assets/img/komin_on.svg") no-repeat;
    }*/

.imgkotol {
    background: url("/../../../content/assets/img/sluzby_kotol.svg") no-repeat top center;
}

   /* .our-services .media:hover .imgkotol {
        background: url("/../../../content/assets/img/kotol_on.svg") no-repeat;
    }*/

.imgplyn {
    background: url("/../../../content/assets/img/sluzby_rozvody.svg") no-repeat top center;
}

    /*.our-services .media:hover .imgplyn {
        background: url("/../../../content/assets/img/plyn_on.svg") no-repeat;
    }*/

.imgblesk {
    background: url("/../../../content/assets/img/sluzby_blesk.svg") no-repeat top center;
}

   /* .our-services .media:hover .imgblesk {
        background: url("/../../../content/assets/img/blesk_on.svg") no-repeat;
    }
*/


.btn-service span {
    display: inline-block;
    padding: 6px 22px;
    border: 1px solid #9fcf8f;
    border-radius: 20px;
    font-size: 16px;
}

.our-services .media:hover .btn-service span {
    border: 1px solid #fff;
    color: #fff;
}

.topbar-top ul li, .topbar-top {
    font-size: 1.2em !important;
}

    .radyList li::marker {
        content: "\f105";
        font: 14px/1 FontAwesome;
    }

    .radyList {
        margin-left: 11px;
    }

    .radyList li {
        padding-left: 6px;
    }

    .btn-phone {
        font-weight: 600 !important;
        font-size: .85714rem !important;
        border-radius: 50px !important;
        color: #52ae32;
        line-height: 1.4;
        transition: .2s ease;
        display: inline-block;
        background: #fff;
    }

    .modal-dialog {
        max-width: 600px !important;
    }

    #carouselHome h1 {
        font-weight: 600 !important;
    }

    .navbar-toggler {
        top: 15px !important;
    }

    .js-header-fix-moment .navbar-toggler {
        top: 12px !important;
    }

    .services-why .g-color-gray-dark-v4 {
        color: #555 !important;
    }

    .service-description h2 {
        margin-bottom: 15px !important;
        font-weight: 600 !important;
    }

    .service-description h3 {
        margin-bottom: 15px !important;
    }

    .service-description p {
        font-size: 1.45rem;
    }

    @media (max-width: 600px) {
        #carouselHome, .why-title {
            text-align: center !important;
        }

        .why-title img {
            margin-bottom: 12px !important;
        }

        #carouselHome .g-font-size-12 {
            font-size: 1.15714rem !important;
        }

        #carouselHome h1.g-mb-10 {
            margin-bottom: 1.81429rem !important;
        }

        .navbar-collapse li a {
            padding-top: 0.47143rem !important;
            padding-bottom: 0.47143rem !important;
        }

        .g-mb-30.g-mb-0--sm {
            margin-bottom: 0 !important;
        }

        .g-pb-30.g-pb-10--sm {
            padding-bottom: 10px !important;
        }

        .g-py-20.g-py-0--sm {
            padding-bottom: 0 !important;
        }

        .g-py-30.g-py-0--sm {
            padding-bottom: 0 !important;
            padding-top: 0 !important;
        }

        .g-mb-60.g-mb-10--sm {
            margin-bottom: 10px !important;
        }

        .news-detail .g-mb-80 {
            margin-bottom: 0 !important;
        }

        .news-detail .g-mb-100 {
            margin-bottom: 0 !important;
        }

        .news-detail .g-mb-50 {
            margin-bottom: 10px !important;
        }

        .g-py-40.g-py-0--sm {
            padding-bottom: 10px !important;
            padding-top: 10px !important;
        }

        .g-py-30.services-why {
            padding-bottom: 0 !important;
            padding-top: 0 !important;
            margin-bottom: 0 !important;
            margin-top: 0 !important;
        }

        .g-py-50.faq {
            padding-bottom: 0 !important;
            padding-top: 0 !important;
        }

        .service-description p.g-mb-30:last-child {
            margin-bottom: 0 !important;
        }

        .service-description .mb-5 {
            margin-bottom: 10px !important;
        }

        .service-price-inner {
            padding: 0 30px 10px 30px !important;
        }

        .order-section {
            padding: 0 !important;
        }

        .order-section h1 {
            font-size: 22px !important;
            font-weight: 600 !important;
        }

        .order-section .g-brd-around.g-brd-gray-light-v3.g-bg-white {
            padding: 0 !important;
            border: 0 !important;
            margin: 0 !important;
        }
    }

    [v-cloak] {
        & > * {
            display: none;
        }

        &::before {
            content: " ";
            display: block;
            width: 16px;
            height: 16px;
            background-image: url('data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==');
        }
    }

.hs-sub-menu .hs-sub-menu {
    top: -10px;
    left: 285px !important;
    margin-top: 0;
}












/* Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.testimonials {
    background: #e6eaec;
}


.testimonials h2 {
    background: url("/../../../content/assets/img/bgh2.png") no-repeat center top;
    padding: 38px 0 64px;
    font-weight: 400;
    font-size: 36px;
    line-height: 36px;
}

#contacts-section h3 {
    color: #b8d8b0;
    font-size: 18px;
}

#contacts-section a, footer a {
    color: #2c2c2c;
}


footer .container {
    background: url("/../../../content/assets/img/done_big.png") no-repeat center top;
    padding-top: 68px;
    padding-bottom: 30px;
}

.why-profi.why-profi-sub {
    background: #e5e9eb;
}

.why-profi.why-profi-sub .row {
    background: none;
}

.why-profi.why-profi-sub h2 {
    font-size: 45px;
}

.why-profi.why-profi-sub ul li {
    background: url(/../../../content/assets/img/done2.png) no-repeat 0 11px;
}

.u-header .g-width-auto--sm {
    width: 167px !important;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .callus a {
        font-size: 35px !important;
        line-height: 1em !important;
    }

    .callus span {
        font-size: 20px !important;
    }

    .our-services {
        padding-bottom: 30px;
    }

    .our-services .col-lg-3 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .g-pt-120--lg {
        padding-top: 3.57143rem !important;
    }

    body.homepage {
        background: url(/../../../content/assets/img/bg/bghome.jpg) no-repeat center top #edefee;
    }

    .navbar-nav li .g-font-size-20, .u-header .pull-left.g-font-size-20 {
        font-size: 16px !important;
    }

    .navbar-nav li ul li .g-font-size-20 {
        font-size: 14px !important;
    }

    .home-welcome h1 {
        font-size: 35px !important;
    }

    .our-services .g-font-size-28 {
        font-size: 1.8rem !important;
    }

    .our-services .media .media-ico {
        height: 53px !important;
        margin-bottom: 22px !important;
        background-size: 53px auto;
    }

    .our-services .media .media-ico.imgkotol {
        background-size: 40px auto;
    }

    .our-services .media .media-ico.imgblesk {
        background-size: 27px auto;
    }


    .Threesteps h2 {
        font-size: 48px;
    }

    .Threesteps .callus span {
        font-size: 28px !important;
    }

    .Threesteps .callus a {
        font-size: 41px !important;
    }

    .why-profi h2 {
        font-size: 44px;
    }

    .why-profi .col-lg-1 {
        display: none;
    }


}

@media (min-width: 768px) and (max-width: 991px) {

    .navbar-nav {
        padding: 20px 15px 0 15px;
    }


    .our-services {
        padding-bottom: 30px;
    }


    .navbar-nav .g-py-15 {
        padding-top: 0 !important;
        padding-bottom: 1.07143rem !important;
    }

    .hs-sub-menu {
        padding: 0 15px !important;
        margin-top: 0 !important;
    }

    .callus a {
        font-size: 35px !important;
        line-height: 1em !important;
    }

    .callus span {
        font-size: 20px !important;
    }

    .g-pt-120--lg {
        padding-top: 3.57143rem !important;
    }

    body.homepage {
        background: url(/../../../content/assets/img/bg/bghome2.jpg) no-repeat center top #edefee;
    }

    .home-welcome .col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .home-welcome .g-mt-60 {
        margin-top: 0.486rem !important;
    }

    .home-welcome .btn.btn--primary {
        width: 330px !important;
        margin-bottom: 0.886rem !important;
    }

    .home-welcome.g-py-60 {
        padding-top: 1.28571rem !important;
        padding-bottom: 2.28571rem !important;
    }

    .navbar-nav li .g-font-size-20, .u-header .pull-left.g-font-size-20 {
        font-size: 16px !important;
    }

    .navbar-nav li ul li .g-font-size-20 {
        font-size: 14px !important;
    }

    .home-welcome h1 {
        font-size: 35px !important;
    }

    .service-detail .home-welcome h2 {
        font-size: 25px !important;
    }

    .service-detail .home-welcome h1 {
        margin-bottom: 5px !important;
    }

    .our-services .media {
        min-height: 1px;
    }

    .our-services .g-font-size-28 {
        font-size: 1.4rem !important;
    }

    .our-services .media .media-ico {
        height: 53px !important;
        margin-bottom: 22px !important;
        background-size: 53px auto;
    }

    .our-services .media .media-ico.imgkotol {
        background-size: 40px auto;
    }

    .our-services .media .media-ico.imgblesk {
        background-size: 27px auto;
    }

    .Threesteps {
        background-image: none;
    }

    .Threesteps h2 {
        font-size: 48px;
    }

    .Threesteps .callus span {
        font-size: 28px !important;
    }

    .Threesteps .callus a {
        font-size: 41px !important;
    }

    .Threesteps .col-lg-3 {
        display: none !important;
    }

    .why-profi h2 {
        font-size: 44px;
    }

    .why-profi ul li {
        font-size: 18px;
    }

    .why-profi .row {
        background: url(/../../../content/assets/img/bgwhy.png) no-repeat 80px 0;
    }

    .js-carousel .g-font-size-22 {
        font-size: 1.37143rem !important;
    }

    .js-carousel .g-font-size-27 {
        font-size: 1.67143rem !important;
    }

    /*.service-detail .home-welcome h1, .home-welcome h3, .home-welcome h2 {
        text-align: center !important;
    }*/


}

@media (max-width: 767px) {
    .callus a {
        font-size: 26px !important;
        line-height: 1em !important;
    }
    .callus, .home-welcome h1, .our-services .media, .Threesteps, .why-profi {
        text-align: center !important;
    }

    .home-welcome.g-py-60 {
        padding-top: 1.28571rem !important;
        padding-bottom: 1.28571rem !important;
    }

    .u-header .g-width-auto--sm {
        width: 127px !important;
    }

    .our-services .media .media-ico {
        height: 61px !important;
        margin-bottom: 10px !important;
        background-position: center top;
    }

    .home-welcome .callus span {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .navbar-collapse {
        padding-left: 10px;
        padding-right: 10px;
    }

    body.homepage {
        background: none #edefee;
    }

    .our-services .g-mb-20 {
        margin-bottom: 1.02857rem !important;
    }

    .our-services.home-services .g-mb-20 {
        margin-bottom: 3.42857rem !important;
    }

    .our-services .media {
        min-height: 1px;
    }

    .media p {
        margin-bottom: 22px !important;
    }

    .callus {
        margin-top: 15px;
    }

    .callus span {
        font-size: 20px !important;
    }

    .g-pt-120--lg {
        padding-top: 3.57143rem !important;
    }

    .our-services .col-md-6 {
        padding-top: 0;
        padding-bottom: 0;
    }

    .our-services .col-md-6.g-mb-30 {
        margin-bottom: 0.143rem !important;
    }

    #contacts-section .g-mb-30 {
        margin-bottom: 1.14286rem !important;
    }

    .our-services .col-md-6:hover {
        background: none !important;
    }


    .navbar-nav li .g-font-size-20, .u-header .pull-left.g-font-size-20 {
        font-size: 16px !important;
    }

    .navbar-nav li ul li .g-font-size-20 {
        font-size: 14px !important;
    }

    .home-welcome h1 {
        font-size: 23px !important;
    }

    .service-detail .home-welcome h1 {
        font-size: 33px !important;
    }

    body.service-detail {
        background: none #edefee;
    }

    .home-welcome .d-sm-none {
        display: none !important;
    }

    .home-welcome h2 {
        font-size: 20px !important;
        text-align: center !important;
    }

    .service-detail .home-welcome p {
        text-align: center !important;
    }

    .service-detail .home-welcome h1 {
        margin-top: 0 !important;
        margin-bottom: 5px !important;
    }

    .faq h2 {
        margin-top: 20px !important;
    }

    .home-welcome h3 {
        font-size: 16px !important;
        text-align: center !important;
    }
    .home-welcome {
        margin-bottom: 20px !important;
    }

    .home-welcome .g-mt-60 {
        margin-top: 0 !important;
    }

    .home-welcome .mb-5 {
        margin-bottom: 1rem !important;
    }

    .our-services .g-font-size-28 {
        font-size: 1.4rem !important;
    }

    .our-services .media .media-ico.imgkotol {
        height: 61px !important;
        background-size: 43px auto;
    }

    .our-services .media .media-ico.imgkomin {
        height: 61px !important;
        background-size: 49px auto;
    }

    .btn-service {
        display: none !important;
    }

    .our-services {
        padding-top: 20px !important;
    }

    .Threesteps {
        background-image: none;
    }

    .Threesteps h2 {
        font-size: 28px;
    }

    .Threesteps .callus span {
        font-size: 20px !important;
    }

    .Threesteps .callus a {
        font-size: 41px !important;
    }

    .Threesteps .btn.btn--primary {
        width: 100% !important;
    }

    .Threesteps .col-lg-3 {
        display: none !important;
    }

    .btn.btn--primary {
        font-size: 18px !important;

    }

    .why-profi h2 {
        font-size: 31px;
    }

    .why-profi ul li {
        font-size: 18px;
        text-align: left;
    }

    .why-profi ul {
        margin-bottom: 0 !important;
    }

    .why-profi .row {
        background: url(/../../../content/assets/img/bgwhy.png) no-repeat 80px 0;
    }

    .why-profi .g-pb-40 {
        padding-bottom: 0 !important;
    }

    .why-profi {
        padding-bottom: 30px !important;
    }

    .js-carousel .g-font-size-22 {
        font-size: 1.37143rem !important;
    }

    .js-carousel .g-font-size-27 {
        font-size: 1.67143rem !important;
    }

    .why-profi .g-pt-70 {
        padding-top: 0rem !important;
    }

    .testimonials h2 {
        font-size: 26px;
    }

    .testimonials .g-py-30 {
        padding-top: 1.14286rem !important;
        padding-bottom: 1.14286rem !important;
    }

    .testimonials .g-py-70 {
        padding-top: 1.14286rem !important;
        padding-bottom: 1.14286rem !important;
    }

    .our-services-sub .g-mb-40 {
        margin-bottom: 1rem !important;
    }

    .why-profi.why-profi-sub h2, .faq h2 {
        font-size: 29px !important;
    }

    .realized h2 {
        font-size: 24px;
    }
}

@media (min-width: 1200px) {
    .why-profi h2, .why-profi .col-md-12 p {
        padding-right: 110px;
    }

}


@media (max-width: 400px) {
    .faq h2, .why-profi.why-profi-sub h2 {
        font-size: 24px !important;
        text-align: center !important;
    }

    .realized h2.g-mb-50 {
        margin-bottom: 1.57143rem !important;
    }

    .realized.g-py-50 {
        padding-top: 1.57143rem !important;
        padding-bottom: 0 !important;
    }

    #contacts-section.g-pt-60 {
        padding-bottom: 1.14286rem !important;
        padding-top: 2.28571rem !important;
    }

    #contacts-section .g-mb-40 {
        margin-bottom: 1.05714rem !important;
    }

    .why-profi .g-pt-40 {
        padding-top: 0 !important;
    }

    .g-py-50.faq {
        padding-top: 20px !important;
    }
}


@media (max-width: 767px) {
    .our-services h3.g-font-size-25 {
        font-size: 1.48571rem !important;
    }

    .why-profi.why-profi-sub .col-md-12 {
        text-align: left !important;
    }

    #contacts-section .g-mb-40 {
        margin-bottom: 1.75714rem !important;
    }

    footer .container {
        padding-top: 68px;
        padding-bottom: 10px;
    }

}

@media (max-width: 320px) {
    .our-services h3.g-font-size-25 {
        font-size: 1.28571rem !important;
    }
}

@media (min-width: 1200px) {
    .our-services.home-services {
        padding-bottom: 30px !important;
    }
}

.fade-default {
    opacity: 0;
}

.come-in {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: come-in 0.6s ease forwards;
    animation: come-in 0.6s ease forwards;
}

.come-in.from-right {
    -webkit-transform: translateY(0) translateX(50px);
    transform: translateY(0) translateX(50px);
}

.come-in.from-left {
    -webkit-transform: translateY(0) translateX(-50px);
    transform: translateY(0) translateX(-50px);
}

.come-in.from-top {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
}

.come-in:nth-child(odd) {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    /* So they look staggered */
}

@-webkit-keyframes come-in {
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes come-in {
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

html[lang=cs] .our-services .media {
    min-height: 1px;
}

.js-header-fix-moment, .js-header-fix-moment .u-header__section {
    background: #fff !important;
}

#cconsent-bar .ccb__wrapper {
    display: block !important;
    margin-right: 170px !important;
}

#cconsent-bar .ccb__right {
    text-align: left;
}

#cconsent-bar .ccb__left {
    display: block;
    margin-right: 100px !important;
    margin: 15px 0;
}


.navbar-expand-lg .navbar-toggler2.bg-none {
    background: none;
}


.navbar-collapse2 {
    display: none !important;
}


.navbar-collapse2.collapse.show, .navbar-toggler2.collapse.show .navbar-nav {
    display: block !important;
}

.navbar-collapse2.collapse.show .navbar-nav ul {
    display: none !important;
}


.navbar-collapse2 .navbar-nav {
    display: block !important;
    width: 250px !important;
    background: #5aac3e !important;
    position: absolute !important;
    top: 80px !important;
    right: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.navbar-collapse2 .navbar-nav .g-ml-45--xl {
    margin-left: 10px !important;
}

.navbar-collapse2 .navbar-nav a {
    color: #fff !important;
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
    font-size: 1em !important;
    line-height: 1.3em !important;
}

@media (min-width: 992px) {
    #navBar .overenie-preukazu {
        display: none !important;
    }
}

    @media (max-width: 1000px) {
        #cconsent-bar .ccb__wrapper, #cconsent-bar .ccb__left {
            margin-right: 0 !important;
        }

        #cconsent-bar .ccb__left {
            margin-bottom: 30px !important;
        }
    }

    @media (max-width: 600px) {
        #cconsent-bar .ccb__right button {
            display: block;
            width: 190px;
            margin-bottom: 1px !important;
            padding: 6px 10px;
        }

        #cconsent-bar .ccb__left {
            margin-top: 0 !important;
            margin-bottom: 9px !important;
        }
    }

    @media (max-width: 992px) {
        .navbar-expand-lg .navbar-toggler2 {
            display: none;
        }
    }

    @media (min-width: 1201px) and (max-width: 1270px) {
        #navBar .g-ml-45--xl, .login-list .g-ml-45--xl {
            margin-left: 30px !important;
        }
    }

    @media (min-width: 992px) and (max-width: 1200px) {
        #navBar .g-ml-45--xl, .login-list .g-ml-45--xl {
            margin-left: 20px !important;
        }
    }

