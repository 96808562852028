//code[class*="language-"],
//pre[class*="language-"] {
//  white-space: pre-line;
//}

pre[class*="language-"] {
  position: relative;
  margin: 0;

  .toolbar {
    position: absolute;
    top: -1px;
    right: -1px;

    &-item {
      a {
        display: block;
        z-index: 10;
        padding: 5px 8px;
        font-size: 12px;
        color: $g-color-gray-dark-v1;
        cursor: pointer;
        background-color: $g-color-white;
        border: 1px solid $g-color-gray-light-v1;
        border-radius: 0 0 0 4px;
      }
    }
  }
}
