/*------------------------------------
  Borders Default (dotted)
------------------------------------*/
.g-brd-around--dotted {
  border: dotted 1px transparent;
}
.g-brd-top--dotted {
  border-top: dotted 1px transparent;
}
.g-brd-bottom--dotted {
  border-bottom: dotted 1px transparent;
}
.g-brd-right--dotted {
  border-right: dotted 1px transparent;
}
.g-brd-left--dotted {
  border-left: dotted 1px transparent;
}

@media (min-width: $g-sm) {
  .g-brd-around--dotted--sm {
    border: dotted 1px transparent;
  }
  .g-brd-top--dotted--sm {
    border-top: dotted 1px transparent;
  }
  .g-brd-bottom--dotted--sm {
    border-bottom: dotted 1px transparent;
  }
  .g-brd-right--dotted--sm {
    border-right: dotted 1px transparent;
  }
  .g-brd-left--dotted--sm {
    border-left: dotted 1px transparent;
  }
}

@media (min-width: $g-md) {
  .g-brd-around--dotted--md {
    border: dotted 1px transparent;
  }
  .g-brd-top--dotted--md {
    border-top: dotted 1px transparent;
  }
  .g-brd-bottom--dotted--md {
    border-bottom: dotted 1px transparent;
  }
  .g-brd-right--dotted--md {
    border-right: dotted 1px transparent;
  }
  .g-brd-left--dotted--md {
    border-left: dotted 1px transparent;
  }
}